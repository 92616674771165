import styled from 'styled-components';

export const QuizAnswersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;

export const QuizTitleEditWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;

  @media screen and (max-width: 568px) {
    gap: 10px;
  }
`;

export const QuizBlockWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  /* background: #0b002c; */
`;
