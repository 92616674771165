import { memo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { GoBackButton } from '@/components';

const GoBack = () => {
  const navigate = useNavigate();
  const { playlistId, videoId } = useParams();

  const goBack = () => {
    if (playlistId && videoId) {
      return navigate(`/account/edit/${playlistId}`);
    }
    navigate('/account/view/');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <GoBackButton onClick={goBack} />
    </div>
  );
};

export default memo(GoBack);
