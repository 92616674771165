import { Button } from '../Shared';
import ArrowTop from '../SvgIcons/ArrowTop';

import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;

export const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Button
      id="scrollToTop"
      onClick={scrollToTop}
      theme="white"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        borderColor: isHovered ? '#BBB6DA' : '#62548B',
        position: 'fixed',
        bottom: '10%',
        right: '5%',
        padding: '15px',
        backgroundColor: 'transparent',
        borderRadius: '12px',
        cursor: 'pointer',
        display: isVisible ? 'block' : 'none',
      }}
    >
      <ArrowTop stroke={!isHovered ? '#BBB6DA' : ''} />
    </Button>
  );
};
