import MyInput from '../MyInput/MyInput';

import styled from 'styled-components';

import { theme } from '@/styles';

export const Wrapper = styled.div`
  position: relative;
`;
export const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  top: 56%;
  transform: translateY(-50%);

  @media (max-width: 1024px) {
    display: none;
  }
`;

// export const StyledInput = styled.input`
//   width: 100%;
//   font-weight: 400;
//   font-size: 17px;
//   line-height: 140%;
//   background: ${theme.colors.blue.blue_5};
//   border: 1px solid transparent;
//   border-radius: 5px;
//   padding: 12px 16px 12px 50px;
//   color: ${theme.colors.white.white_100};
//   transition: all 0.3s;

//   @media (max-width: 1024px) {
//     padding: 12px 16px;
//   }

//   ::placeholder {
//     color: ${theme.colors.text.white_30};
//   }

//   :hover {
//     border: 1px solid ${theme.colors.white.white_100};
//   }

//   :focus {
//     border: 1px solid ${theme.colors.white.white_100};
//   }
// `;

export const StyledSearchInput = styled(MyInput)`
  & input {
    padding-left: 50px;
  }

  @media screen and (max-width: 1024px) {
    & input {
      padding-left: 20px;
      background: ${theme.colors.blue.blue_modal};
    }
  }
`;
