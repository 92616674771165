import styled from 'styled-components';

export const QuizVariantWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;

  @media screen and (max-width: 568px) {
    gap: 10px;
  }
`;
