import Button from '../../Buttons/Button/Button';
import FullScreenLoader from '../../FullScreenLo/FullScreenLoader';
import MyInput from '../../MyInput/MyInput';
import { ButtonsWrapperResetPass, FieldsWrapper, Form, ResetPasswordInputWrapper } from '../Login/Login.styled';
import { Modal, OtherText, Title } from '../Modal';
import { Info } from '../Registration/Registration.styled';
import { Inner } from '../RemoveUser/RemoveUser.styled';

import { useReducer, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { usersAPI } from '@/api';
import { useActions, useHandlingError, useAppSelector } from '@/hooks';
import { isAuthData } from '@/utils/isAuthData';

export const ResetPassword = () => {
  const { t } = useTranslation('components');
  const { closeModal } = useActions();
  const [, setParams] = useSearchParams();

  const [success, setSuccess] = useReducer((v) => !v, false);

  const [email] = useAppSelector((state) => [state.modal.data?.email]);

  const { catchError } = useHandlingError();

  const [resetPassword, status] = usersAPI.useResetPasswordMutation();

  const mess = t('set_pass_reset_lint', { ns: 'error' });
  const [errorAlertIsOpen, setErrorAlertIsOpen] = useState<boolean>(status.isError);
  const [errorAlertMessage, setErrorAlertMessage] = useState<string>(mess);

  const closeHandler = () => {
    reset();
    setErrorAlertIsOpen(false);
    closeModal();
    setParams();
  };

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm<{ email: string }>({ defaultValues: { email: '' } });

  const onSubmit = async (data: { email: string }) => {
    try {
      await resetPassword(data).unwrap();
      setSuccess();
    } catch (err) {
      const errorData = (err as FetchBaseQueryError).data;
      if (isAuthData(errorData)) {
        const words = errorData.data;

        const email = getValues('email');
        const message = words.includes('email')
          ? t('inverify_email', { ns: 'error', email })
          : t('incor_cred', { ns: 'error' });
        setErrorAlertMessage(message);
        setErrorAlertIsOpen(true);
      } else {
        catchError(err);
      }
    }
  };

  if (success) {
    return (
      <Modal modal={'resetpassword'} onClose={closeHandler} closeButton>
        <Title>{t('res_pass', { ns: 'modal' })}</Title>
        <Inner>
          <OtherText>{t('verify_email_info', { ns: 'modal', email })}</OtherText>
          <Info style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <span>{t('verify_email_no_send', { ns: 'modal' })}</span>
            <Button
              style={{ padding: '0px', fontSize: '10px' }}
              size="small"
              theme="transparent"
              onClick={() => setParams({ popup: 'resendEmail' })}
            >
              {t('verify_email_resend', { ns: 'modal' })}
            </Button>
          </Info>
        </Inner>
      </Modal>
    );
  }

  return (
    <Modal modal={'resetpassword'} onClose={closeHandler} closeButton>
      <Title>{t('res_pass', { ns: 'modal' })}</Title>
      <Inner>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Collapse in={errorAlertIsOpen}>
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorAlertIsOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              <AlertTitle>{t('log', { ns: 'error' })}</AlertTitle>
              {errorAlertMessage}
            </Alert>
          </Collapse>
          <FieldsWrapper>
            <ResetPasswordInputWrapper>
              <MyInput
                required
                label={t('form.email')}
                {...register('email', {
                  required: { value: true, message: t('req_field', { ns: 'error' }) },
                  minLength: { value: 5, message: t('min_char', { ns: 'error', length: 5 }) },
                  maxLength: { value: 256, message: t('max_char', { ns: 'error', length: 256 }) },
                  pattern: {
                    value: /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-z]{2,6}$/,
                    message: t('incor_email', { ns: 'error' }),
                  },
                })}
                // placeholder={t('form.email')}
                error={errors.email}
              />
            </ResetPasswordInputWrapper>
          </FieldsWrapper>
          <ButtonsWrapperResetPass>
            <Button theme="white" type="submit" disabled={status.isLoading}>
              {/* {t('menu.in', { ns: 'header' })} */}
              {t('form.send')}
            </Button>
            <Button theme="inline" type="reset" onClick={closeHandler}>
              {t('form.cancel')}
            </Button>
          </ButtonsWrapperResetPass>
        </Form>

        {status.isLoading && <FullScreenLoader />}
      </Inner>
    </Modal>
  );
};
