import { IframeThemeType } from '../../types/iframeTypes';

const ArrowRight = ({ iframeTheme,isIframe, ...props }: { iframeTheme?: IframeThemeType, isIframe?: boolean }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 6L15 12L9 18"
      stroke={`${isIframe && iframeTheme === IframeThemeType.light ? '#000046' : '#E4E4FF'}`}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowRight;
