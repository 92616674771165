import videofilePrevios from './images/videofile-previos.png';

import { Title } from '../PlaylistCard';

import { memo, Reducer, useReducer } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, type To, useLocation } from 'react-router-dom';

import { InnerCard, PreviousVideoFile, VideoCardWrapper, VideoMenu } from '@/components/Card';
import DropDown from '@/components/Shared/DropDown';
import DropDownMenuItem from '@/components/Shared/DropDown/DropDownMenu/DropDownIMenuItem';
import DotsVerticalIcon from '@/components/SvgIcons/DotsVerticalIcon';
import TrashIcon from '@/components/SvgIcons/TrashIcon';
import { useActions } from '@/hooks';
import { VideoFile } from '@/types';

export interface VideoFileCardProps {
  videoFile: VideoFile;
  menu?: boolean;
  to?: To;
}

const VideoFileCard = ({ menu = false, videoFile, to }: VideoFileCardProps) => {
  const { file, publicId } = videoFile;
  const location = useLocation();
  const { openModal } = useActions();
  const { t } = useTranslation('components');

  const [opened, setOpened] = useReducer<Reducer<boolean, boolean>>((v: boolean) => !v, false);
  const titleVideoFile = file && file.split('/').at(-1);

  const toggleHandler = () => {
    setOpened(opened);
  };

  const dropDownMenuItems = [
    <DropDownMenuItem
      key={6}
      icon={<TrashIcon />}
      text={t('menu.del_file_btn')}
      onClick={() =>
        openModal({
          active: 'RemoveVideoFile',
          data: {
            id: publicId,
          },
        })
      }
    />,
  ];

  return (
    <VideoCardWrapper>
      <Link
        to={to ?? `watch/${publicId}?type=videoFile&start=${0}`}
        state={`${location.pathname}`}
        style={{ cursor: 'pointer' }}
      >
        <PreviousVideoFile src={videofilePrevios} alt="icon-video-file" />
      </Link>
      <InnerCard>
        <Title>{titleVideoFile}</Title>
        <VideoMenu>
          {menu && (
            <DropDown items={dropDownMenuItems} isOpen={opened} toggleOpen={toggleHandler}>
              <DotsVerticalIcon />
            </DropDown>
          )}
        </VideoMenu>
      </InnerCard>
    </VideoCardWrapper>
  );
};

export default memo(VideoFileCard);
