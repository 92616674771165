import { api } from '../api';

import { BaseParams, GetList, QuizApiResponse } from '@/types';

const PATH = 'videos';

export const quizzesAdminAPI = api.injectEndpoints({
  endpoints: (build) => ({
    //получаем квизы по ид видео
    getAllQuizzesAdmin: build.query<QuizApiResponse, { videoId: string; params?: BaseParams }>({
      query: ({ videoId, params }) => ({
        url: `videos/${videoId}/quizzes/`,
        method: 'GET',
        params,
      }),
      providesTags: (_, __, { videoId }) => [{ type: 'control_panel_quiz', id: videoId }],

      transformResponse: (res: GetList<QuizApiResponse>) => res.results?.[0],
    }),

    getOneQuizAdmin: build.query<QuizApiResponse, { videoId: string; publicId: string; params?: BaseParams }>({
      query: ({ publicId, videoId }) => ({
        url: `${PATH}/${videoId}/quizzes/${publicId}/`,
        method: 'GET',
      }),
      providesTags: (_, __, { videoId }) => [{ type: 'control_panel_quiz', id: videoId }],
    }),

    patchQuizzesAdmin: build.mutation<
      QuizApiResponse,
      { videoId: string; publicId: string; body: Pick<QuizApiResponse, 'data'>; params?: BaseParams }
    >({
      query: ({ videoId, publicId, body }) => ({
        url: `${PATH}/${videoId}/quizzes/${publicId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, { videoId }) => [{ type: 'control_panel_quiz', id: videoId }],
    }),

    deleteQuizzesAdmin: build.mutation<void, { videoId: string; publicId: string }>({
      query: ({ videoId, publicId }) => ({
        url: `${PATH}/${videoId}/quizzes/${publicId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { videoId }) => [{ type: 'control_panel_quiz', id: videoId }],
    }),

    putQuizzesAdmin: build.mutation<
      QuizApiResponse,
      { videoId: string; publicId: string; body: Pick<QuizApiResponse, 'data'> }
    >({
      query: ({ videoId, publicId, body }) => ({
        url: `${PATH}/${videoId}/quizzes/${publicId}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { videoId }) => [{ type: 'control_panel_quiz', id: videoId }],
    }),
  }),
});

export const { useGetAllQuizzesAdminQuery, usePatchQuizzesAdminMutation, useDeleteQuizzesAdminMutation } =
  quizzesAdminAPI;

export const { getAllQuizzesAdmin } = quizzesAdminAPI.endpoints;
