import {Summary} from './components/Summary';
import {Timecodes} from './components/Timecodes';
import {
  ButtonTab,
  CardList,
  ContainerVideoPage,
  InfoBlockPlaylistMaterial,
  Inner,
  Panel,
  PanelButtons,
  TabWrapper,
  TextTab,
  Title,
  VideoPageInputWrapper,
  VideoWrapper,
} from './VideoPage.styled';

import FullScreenLoader from '../../components/Shared/FullScreenLo/FullScreenLoader';
import {Quiz} from '../QuizPage/Quiz';

import {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {useTranslation} from 'react-i18next';
import { useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {videosAPI} from '@/api';
import {FileVideoPlayer, VkVideoPlayer, YouTubeVideoPlayer} from '@/components';
import {VideoFragmentCard} from '@/components/Card';
import {CopyIframeButton} from '@/components/CopyIframeButton/CopyIframeButton';
import SearchInVideoInput from '@/components/Shared/SearchInVideoInput/SearchInVideoInput';
import {useActions, useAppSelector, useHandlingError, useMatchMedia} from '@/hooks';
import {InfoText} from '@/pages/AdminPage/AdminPage.styled';
import {IframeLangType, IframeThemeType} from '@/types/iframeTypes';
import {TitleH5} from "@/styles";
import {useIframe} from "@/hooks/useIframe";
import {useIframeHeight} from "@/hooks/useIframeHeight";

const VideoPage = () => {
  useIframeHeight();

  const { t, i18n } = useTranslation();
  const { id, hash } = useParams();

  const [params, setParams] = useSearchParams();
  const [currentTime, setCurrentTime] = useState(0);
  const { catchError } = useHandlingError();
  const isTablet = useMatchMedia('(max-width: 768px)');
  const [tab, setTab] = useState(1);
  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate()
  const search = params.get('search');
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const isCommercial = useAppSelector((state) => state.user.isCommercial);

  const {isIframe, iframeTheme, iframeLang, showTest} = useIframe()

  const { showNotification } = useActions();

  const { data: video, error: videoError } = videosAPI.useGetMovieByIdQuery(
    { id: id ?? '' },
    { skip: !isAuth && !iframeTheme },
  );

  const [getSearchInVideo, { data: searchInVideo, isLoading: isSearchLoading, error: searchError }] =
    videosAPI.useLazyGetFullSearchInVideoQuery();

  const getSearchVideosHandler = useCallback(
    async (query: string) => {
      await getSearchInVideo({ query, videoId: video?.videoId ?? '' });
    },
    [video],
  );

  useEffect(() => {
    if (!isAuth && !iframeTheme) {
      showNotification({
        text: t('w_video', { ns: 'error' }),
        severity: 'error',
      });
      setParams((params) => {
        params.set('popup', 'login');
        return params;
      });
    }
  }, [isAuth]);

  useEffect(() => {
    switch (iframeLang) {
      case IframeLangType.RU:
        i18n.changeLanguage('ru');
        break;
      case IframeLangType.EN:
        i18n.changeLanguage('en');
        break;
      default:
        i18n.changeLanguage('en');
    }
  }, [iframeLang]);

  useEffect(() => {
    catchError(videoError || searchError);
  }, [videoError, searchError]);

  const iframe = useRef<{ goToTime: (time: number) => void } | null>(null);
  const iframeWrapper = useRef<HTMLDivElement>(null);

  const height = isTablet ? '300px' : '500px';
  const disabledInput = video && video.transcriptionStatus !== 'DONE';
  const disabledTabs = video ? video.timecodesStatus === 'NOT_STARTED' : false;

  const goToTime = useCallback(
    (time: number) => {
      iframe.current?.goToTime(time);
      iframeWrapper.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    [video],
  );

  const startsForm = useMemo(() => {
    const time = params.get('t');
    return time ? parseInt(time) : 0;
  }, [params]);

  return (
    <ContainerVideoPage iframeTheme={iframeTheme as IframeThemeType}>
      <Inner>
        {isIframe && <TitleH5 iframeTheme={iframeTheme as IframeThemeType} onClick={() => navigate(-1)}>{t('goBack', {ns: 'components'})}</TitleH5>}

        {video && (
          <>
            <div>
              <VideoWrapper ref={iframeWrapper} iframeTheme={iframeTheme as IframeThemeType}>
                {video.source === 'YOUTUBE' && (
                  <YouTubeVideoPlayer
                    ref={iframe}
                    id={video.videoId}
                    title={video.title}
                    startsFrom={video.startsFrom ?? startsForm}
                    height={height}
                    setCurrentTime={(time: number) => setCurrentTime(time)}
                  />
                )}

                {video.source === 'VK' && (
                  <VkVideoPlayer
                    ref={iframe}
                    title={video.title}
                    startsFrom={startsForm}
                    originLink={video.originLink}
                    height={height}
                    setCurrentTime={(time: number) => setCurrentTime(time)}
                  />
                )}
                {video.source === 'UPLOADED' && (
                  <FileVideoPlayer
                    ref={iframe}
                    height={height}
                    url={video.originLink}
                    startsFrom={startsForm}
                    setCurrentTime={(time: number) => setCurrentTime(time)}
                  />
                )}
              </VideoWrapper>
            </div>
            <Title isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{video.title}</Title>
          </>
        )}

        {(isAuth || iframeTheme) && video && (
          <>
            <div>
              <Panel style={iframeTheme ? { marginBottom: '15px' } : {}}>

                <PanelButtons>
                  <VideoPageInputWrapper>
                    {(isAuth || iframeTheme) && (
                      <SearchInVideoInput
                        isIframe={isIframe}
                        iframeTheme={iframeTheme as IframeThemeType}
                        getSearch={getSearchVideosHandler}
                        disabled={disabledInput}
                      />
                    )}
                  </VideoPageInputWrapper>

                  {isCommercial && !isIframe && <CopyIframeButton videoId={id ?? ''} />}
                </PanelButtons>
                
                <TabWrapper
                  onMouseOver={() => setShowInfo(true)}
                  onMouseOut={() => setShowInfo(false)}
                  iframeTheme={iframeTheme as IframeThemeType}
                  isIframe={isIframe}
                  disabled={disabledTabs}
                >
                  <ButtonTab
                    disabled={disabledTabs}
                    theme={`${tab === 1 ? 'white' : 'transparent'}`}
                    onClick={() => setTab(1)}
                  >
                    <TextTab isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
                      {t('timecodes', { ns: 'components' })}
                    </TextTab>
                  </ButtonTab>
                  <ButtonTab
                    disabled={disabledTabs}
                    theme={`${tab === 2 ? 'white' : 'transparent'}`}
                    onClick={() => setTab(2)}
                  >
                    <TextTab isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{t('summary', { ns: 'components' })}</TextTab>
                  </ButtonTab>
                {(showTest === '1' || isAuth) &&
                      <ButtonTab
                          disabled={disabledTabs}
                          theme={`${tab === 3 ? 'white' : 'transparent'}`}
                          onClick={() => setTab(3)}
                      >
                          <TextTab isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{t('quiz', {ns: 'components'})}</TextTab>
                      </ButtonTab>
                  }
                </TabWrapper>
              </Panel>
              {showInfo && disabledTabs && (
                <InfoBlockPlaylistMaterial isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
                  <InfoText isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
                    {t('info_public_pl_text', { ns: 'components' })}
                  </InfoText>
                </InfoBlockPlaylistMaterial>
              )}
              {disabledInput && (
                <Title isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
                  {t('info_public_pl_search', { ns: 'components' })}
                </Title>
              )}

              {search && searchInVideo && video && (
                <>
                  {searchInVideo.length === 0 && (
                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                      <Title isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{t('main.no_result', { ns: 'pages' })}</Title>
                    </div>
                  )}
                  <CardList>
                    {searchInVideo.map((fragment) =>
                      fragment.cues.map((cue, i) => {
                        if (fragment.publicId === video.publicId) {
                          return (
                            <VideoFragmentCard
                              isIframe={isIframe}
                              iframeTheme={iframeTheme as IframeThemeType}
                              fragment={cue}
                              key={fragment.publicId + i}
                              goToTime={goToTime}
                              videoPreview={fragment.thumbnailUrl}
                            />
                          );
                        }
                      }),
                    )}
                  </CardList>
                </>
              )}
              {isSearchLoading && <FullScreenLoader />}
            </div>
            {tab === 1 && (
              <Timecodes
                timecodesStatus={video && video.timecodesStatus}
                id={id || ''}
                hash={hash}
                setTime={goToTime}
                currentTime={currentTime}
              />
            )}
            {tab === 2 && (
              <Summary
                summaryStatus={video && video.summaryStatus}
                videoPublicId={id || ''}
                hash={hash}
              />
            )}
            {tab === 3 && (
              <Quiz
                quizStatus={video && video.quizzStatus}
                videoPublicId={id || ''}
                hash={hash}
                goToTime={(time: string | number) => goToTime(time as number)}
              />
            )}
          </>
        )}
      </Inner>
    </ContainerVideoPage>
  );
};

export default memo(VideoPage);
