import { ButtonsWrapper, Inner } from './RemoveTimecodes.styled';

import { Modal, Title } from '../Modal';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDeleteTimecodesAdminMutation } from '@/api';
import { FullScreenLoader, Button } from '@/components';
import { useActions } from '@/hooks';

interface RemoveTimecodesProps {
  publicId: string;
  videoPk: string;
}
export const RemoveTimecodes = ({ publicId, videoPk }: RemoveTimecodesProps) => {
  const { t } = useTranslation('components');
  const { closeModal, showNotification } = useActions();

  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [deleteTimecodes, { isLoading: isDeleting }] = useDeleteTimecodesAdminMutation();

  const deletePL = () => {
    const result = deleteTimecodes({ publicId, videoPk });
    if ('error' in result) {
      showNotification({
        text: t('del_timecodes', { ns: 'error' }),
        severity: 'error',
      });
      setIsDelete(true);
    } else {
      closeModal();
      showNotification({
        text: t('del_timecodes', { ns: 'success' }),
        severity: 'success',
      });
    }
  };

  return (
    <Modal modal={'RemoveTimecodes'} closeButton>
      <Inner>
        <Title>{t('delete_timecodes')}</Title>
        <ButtonsWrapper>
          <Button theme={isDelete ? 'disabled' : 'red'} onClick={deletePL} disabled={isDelete}>
            {t('form.del')}
          </Button>
          <Button onClick={() => closeModal()} theme="inline">
            {t('form.cancel')}
          </Button>
        </ButtonsWrapper>
        {isDeleting && <FullScreenLoader />}
      </Inner>
    </Modal>
  );
};
