import { EditEditor } from './EditEditor';
import { Buttons, Wrapper } from './EditTranscriptPage.styled';

import { useParams } from 'react-router-dom';

import { useGetTranscriptsAdminQuery } from '@/api';
import { Button } from '@/components';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { useState } from 'react';
// import { EditorState } from 'draft-js';

export const EditTranscriptPage = () => {
  const { videoId } = useParams();

  const { data: transcripts } = useGetTranscriptsAdminQuery(
    { videoPk: videoId || '' },
    {
      skip: !videoId,
    },
  );

  // const [transcript] = useState(() => EditorState.createEmpty());

  console.log(transcripts);

  return (
    <Wrapper>
      {transcripts?.results[0].data && <EditEditor />}
      {/* {transcripts?.results[0].data && <EditEditor content={transcripts?.results[0].data.text} />} */}

      <Buttons>
        <Button theme="white">Сохранить</Button>
        <Button>Удалить</Button>
      </Buttons>
    </Wrapper>
  );
};
