import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '@/hooks';


interface RequireAuthProps {
  children: JSX.Element;
}

const RequireAuth = ({ children }: RequireAuthProps) => {
  const location = useLocation();
  const isAuth = useAppSelector((state) => state.user.isAuth);

  if (isAuth) {
    return children;
  } else {
    return <Navigate to="/" state={{ from: location }} />;
  }
};

export default RequireAuth;
