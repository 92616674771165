import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: flex-start;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: flex-start;
`;

export const TimecodesPlayDeleteButtons = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    gap: 11px;
  }
`;

export const TitleTimeWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 568px) {
    gap: 10px;
  }
`;
