import { theme } from '../../../../styles/theme';
import { Button } from '../Button';

import styled, { css } from 'styled-components';

export const Wrapper = styled(Button)<{ checked: boolean; isError: boolean }>`
  width: 54px;
  height: 52px;
  transition: all 0.3s linear;
  padding: 12px;
  :hover {
    scale: 1.02;
  }
  :active {
    scale: 0.98;
  }
  ${(props) => {
    switch (props.theme) {
      case 'red':
        return css`
          /* background: ${theme.colors.red.red_light}; */
          & path {
            stroke: ${theme.colors.blue.blue_20};
          }
        `;

      case 'black':
        return css`
          /* background: ${theme.colors.blue.blue_20}; */
          & path {
            stroke: ${theme.colors.white.white_100};
          }
        `;
      case 'white':
        return css`
          /* background: ${theme.colors.white.white_100}; */
          & path {
            stroke: ${theme.colors.blue.blue_20};
          }
        `;
      case 'none':
        return css`
          /* background: transparent; */
          & path {
            stroke: ${theme.colors.blue.blue_20};
          }
        `;
      case 'outline':
        return css`
          border: 2px solid ${theme.colors.white.white_30};
          & path {
            stroke: ${theme.colors.white.white_30};
          }
          :hover {
            border: 2px solid ${theme.colors.white.white_80};

            & path {
              stroke: ${theme.colors.white.white_80};
            }
          }

          :active {
            & path {
              stroke: ${theme.colors.white.white_80};
            }
          }
        `;
      default:
    }
  }}

  ${(props) =>
    props.isError &&
    css`
      border: 2px solid ${theme.colors.red.red_light};
      & path {
        stroke: ${theme.colors.red.red_light};
      }
    `}
        ${(props) =>
    props.checked &&
    css`
      border: 2px solid #7aef8d;
      & path {
        stroke: #7aef8d;
      }
      :hover {
        border-color: #7aef8d;
        & path {
          stroke: #7aef8d;
        }
      }
    `}
`;
