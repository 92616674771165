import {ContainerStyled} from './Container.styled';

import {PropsWithChildren} from 'react';
import {IframeThemeType} from "@/types/iframeTypes";
import {useIframe} from "@/hooks/useIframe";

const Container = ({ children }: PropsWithChildren) => {
  const {iframeTheme} = useIframe()
  return <ContainerStyled iframeTheme={iframeTheme as IframeThemeType}>{children}</ContainerStyled>
};

export default Container;
