import styled, { css } from 'styled-components';

import { theme } from '@/styles';
import { IframeThemeType } from '@/types/iframeTypes';

export const QuizStyled = styled.div<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  position: relative;
  min-height: 345px;
  gap: 15px;
  padding: 20px;
  border-radius: 10px;
  background-color: ${theme.colors.blue.blue_20};

  @media (max-width: 480px) {
    padding: 10px;
  }

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      background: rgba(60, 64, 67, 0.07);
    `}
`;

export const Text = styled.span<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: ${theme.colors.text.white_80};

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}

  @media (max-width: 1028px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }
`;
