import { ModalProps } from './Modal.props';
import { ModalStyled, Content, Overlay, ExitButtonWrapper } from './Modal.styled';

import CrossIcon from '../../../SvgIcons/CrossIcon';
import { Portal } from '../../Portal/Portal';

import { MouseEvent, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useActions, useMount, useAppSelector } from '@/hooks';

export const Modal = ({ modal, children, closeButton = false, onClose }: ModalProps) => {
  const [tokenA, activeModal] = useAppSelector((state) => [state.user, state.modal.active]);
  const { mounted } = useMount(activeModal === modal);
  const [, setParams] = useSearchParams();

  const { closeModal, logout } = useActions();

  useEffect(() => {
    if (!tokenA && (modal !== 'registration' || 'login')) {
      closeModal();
      logout();
    }
  }, [tokenA, modal]);

  const close = () => {
    if (onClose) {
      onClose();
    } else {
      setParams((params) => {
        params.delete('popup');
        return params;
      });
      closeModal();
    }
  };

  const handleOverlayClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      close();
    }
  };

  if (mounted) {
    return (
      <Portal>
        <ModalStyled>
          <Overlay open={activeModal === modal} onClick={handleOverlayClick} />
          <Content open={activeModal === modal} modal={activeModal} id="modal">
            <>
              {children}
              {closeButton && (
                <ExitButtonWrapper type="reset" onClick={close}>
                  <CrossIcon />
                </ExitButtonWrapper>
              )}
            </>
          </Content>
        </ModalStyled>
      </Portal>
    );
  }
  return null;
};
