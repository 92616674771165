import styled, { css } from 'styled-components';

import { Button } from '@/components';
import { TitleH4, theme } from '@/styles';
import { IframeThemeType } from '@/types/iframeTypes';

export const Wrapper = styled.div<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  background: ${theme.colors.blue.blue_20};
  border-radius: 10px;
  padding: 30px;
  min-height: 250px;
  position: relative;

  @media (max-width: 480px) {
    padding: 10px;

    & span,
    & li {
      font-size: 14px;
      line-height: 18px;
    }

    & h2 span {
      font-weight: bold;
      font-size: 16px;
    }

    & .rdw-editor-main {
      padding: 4px 12px !important;
    }
  }

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      background: white;
    `}
  
  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme &&
    css`
        padding: 0px;
    `}
`;

export const TimecodesWrapper = styled.div<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  height: 500px;
  overflow: auto;
  background: ${theme.colors.blue.blue_20};
  border-radius: 10px;
  padding: 30px;

  @media (max-width: 1028px) {
    padding: 20px;
  }
  @media (max-width: 768px) {
    padding: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 10px; /* ширина scrollbar */
    overflow: hidden;
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* цвет дорожки */
    overflow: hidden;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: rgb(30 21 199 / 23%); /* цвет плашки */
  }

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      background: rgba(60, 64, 67, 0.07);
    `}
  
  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme &&
    css`
        padding: 30px 10px;
        ::-webkit-scrollbar {
            display: none;
        }
        height: auto;
    `}
`;

export const Title = styled(TitleH4)``;

export const TimecodesTitle = styled(TitleH4)<{ isTarget: boolean; iframeTheme: IframeThemeType, isIframe?: boolean }>`
  background-color: ${(props) => (props.isTarget ? '#BE9F4B' : 'transparent')};

  font-weight: 600;
  @media (max-width: 1028px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 18px;
  }

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const TimecodesContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  position: relative;

  @media (max-width: 768px) {
    gap: 5px;
  }
`;

export const TimecodesTitleWrapper = styled.div<{iframeTheme: IframeThemeType, isIframe?: boolean}>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }

  :active {
    scale: 0.99;
  }
  transition: opacity 0.25s ease-in-out;

    @media (max-width: 1028px) {
        ${({iframeTheme, isIframe}) =>
                isIframe && iframeTheme &&
                css`
                    gap: 30px;
                `}
    }
    
    @media (max-width: 768px) {
        ${({iframeTheme,isIframe}) =>
               isIframe && iframeTheme &&
                css`
                    gap: 5px;
                `}
    }
   
`;

export const List = styled.ul<{iframeTheme: IframeThemeType, isIframe?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 20px;

    @media (max-width: 1028px) {
        ${({iframeTheme, isIframe}) =>
               isIframe && iframeTheme &&
                css`
                    gap: 10px;
                `}
    }
`;

export const ListItem = styled.li<{ iframeTheme: IframeThemeType, isIframe?: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (max-width: 1028px) {
        ${({iframeTheme, isIframe}) =>
                isIframe && iframeTheme &&
                css`
                    gap: 0px;
                `}
    }

    /* margin-top: 20px; */

  /* :focus  {
    h4 {
      background-color: #BE9F4B;
    }
  } */
`;

export const Time = styled.span<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #dc81ff;
  cursor: pointer;
  width: 120px;
  padding: 7px;
  border-radius: 5px;
  flex-shrink: 0;
  transition: color 0.4s ease-in-out;

    @media (max-width: 1028px) {
        font-size: 20px;
        width: 95px;
        text-align: left;
        padding: 5px;
    }
    
    @media (max-width: 480px) {
        font-size: 14px;
        width: 67px;
    }

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const TextWrapper = styled.div<{iframeTheme: IframeThemeType, isIframe?: boolean}>`
  display: flex;
  padding-left: 80px;

    ${({iframeTheme}) =>
            iframeTheme &&
            css`
                  padding-left: 130px;
              `};

    @media (max-width: 768px) {
        padding-left: 60px;

        ${({iframeTheme, isIframe}) =>
                isIframe && iframeTheme &&
                css`
                  padding-left: 100px;
              `};
    }

  @media (max-width: 480px) {
    padding-left: 60px;

      ${({iframeTheme, isIframe}) =>
             isIframe && iframeTheme &&
              css`
                  padding-left: 70px;
              `};
  }
`;

export const Text = styled.span<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: ${theme.colors.text.white_80};

  @media (max-width: 1028px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const TextLink = styled.span<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  margin-left: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  color: ${theme.colors.text.white_100};

  @media (max-width: 1028px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    line-height: 14px;
  }
  cursor: pointer;
  :hover {
    color: ${theme.colors.text.white_80};
  }
  transition: color 0.35s ease-in-out;

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const MoreButton = styled(Button)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  @media (max-width: 1028px) {
    font-size: 15px;
    padding: 10px 20px;
  }
  @media (max-width: 768px) {
    background-color: #210071;
    font-size: 13px;
    padding: 5px 10px;
    bottom: 0;
    right: 0;
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: flex-end;
    gap: 5px;
  }
`;

export const ButtonDownloadSummary = styled(Button)<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      background-color: rgb(228, 228, 255);
      border-color: rgb(228, 228, 255);

      &:active {
        background-color: rgb(187, 182, 218);
      }
    `}
`;
