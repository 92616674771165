import { ButtonsWrapper, Inner } from './RemoveTimecodes.styled';

import { Modal, Title } from '../Modal';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { summariesAdminApi } from '@/api/videosApi/summariesAdminApi';
import { FullScreenLoader, Button } from '@/components';
import { useActions } from '@/hooks';

interface RemoveSummaryProps {
  publicId: string;
  videoId: string;
}
export const RemoveSummary = ({ publicId, videoId }: RemoveSummaryProps) => {
  const { t } = useTranslation('components');
  const { closeModal, showNotification } = useActions();

  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [deleteSummary, { isLoading: isDeleting }] = summariesAdminApi.useDeleteSummaryAdminMutation();

  const deletePL = () => {
    const result = deleteSummary({ publicId, videoId });
    if ('error' in result) {
      showNotification({
        text: t('del_summary', { ns: 'error' }),
        severity: 'error',
      });
      setIsDelete(true);
    } else {
      closeModal();
      showNotification({
        text: t('del_summary_success', { ns: 'success' }),
        severity: 'success',
      });
    }
  };

  return (
    <Modal modal={'RemoveSummary'} closeButton>
      <Inner>
        <Title>{t('delete_summary_admin')}</Title>
        <ButtonsWrapper>
          <Button theme={isDelete ? 'disabled' : 'red'} onClick={deletePL} disabled={isDelete}>
            {t('form.del')}
          </Button>
          <Button onClick={() => closeModal()} theme="inline">
            {t('form.cancel')}
          </Button>
        </ButtonsWrapper>
        {isDeleting && <FullScreenLoader />}
      </Inner>
    </Modal>
  );
};
