import Button from '../../Buttons/Button/Button';
import FullScreenLoader from '../../FullScreenLo/FullScreenLoader';
import { Modal, Title } from '../Modal';
import { ButtonsWrapper, Inner } from '../RemovePlaylist/RemovePlaylist.styled';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { playlistsAPI, videosAPI } from '@/api';
import { useAppSelector, useActions } from '@/hooks';

export const RemoveVideo = () => {
  const { t } = useTranslation('components');
  const { closeModal, showNotification } = useActions();
  const [id, playlistId] = useAppSelector((state) => [state.modal.data?.id, state.modal.data?.playlistId]);
  const [isDelete, setIsDelete] = useState(false);

  const [deleteMovie, result] = videosAPI.useDeleteVideoMutation();
  const [removeFromPlaylist, resultPlaylist] = playlistsAPI.useRemoveVideoFromPlaylistMutation();

  const showSuccess = () => {
    closeModal();
    showNotification({
      text: t('del_v', { ns: 'success' }),
      severity: 'success',
    });
  };

  const showError = () => {
    showNotification({
      text: t('del_v', { ns: 'error' }),
      severity: 'error',
    });
    setIsDelete(true);
  };

  const deleteM = async () => {
    try {
      if (id && playlistId) {
        removeFromPlaylist({ videoPublicId: id, playlistId }).unwrap();
      } else if (id) {
        deleteMovie({ id }).unwrap();
      }
      showSuccess();
    } catch (e) {
      showError();
    }
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal modal={'RemoveVideo'} closeButton>
      <Inner>
        <Title>{t('del_v', { ns: 'modal' })}</Title>
        <ButtonsWrapper>
          <Button theme={isDelete ? 'disabled' : 'red'} onClick={deleteM} disabled={isDelete}>
            {t('form.del')}
          </Button>
          <Button onClick={handleClose} theme="inline">
            {t('form.cancel')}
          </Button>
        </ButtonsWrapper>
        {(result.isLoading || resultPlaylist.isLoading) && <FullScreenLoader />}
      </Inner>
    </Modal>
  );
};
