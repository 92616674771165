import MyInput from '../MyInput/MyInput';

import styled, {css} from 'styled-components';

import {IframeThemeType} from "@/types/iframeTypes";

export const Wrapper = styled.div`
  position: relative;
`;
export const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  top: 56%;
  transform: translateY(-50%);
`;

export const StyledInput = styled(MyInput)<{iframeTheme: IframeThemeType, isIframe?: boolean}>`
  & input {
    padding-left: 50px;
      
      ${({ iframeTheme, isIframe }) =>
             isIframe && iframeTheme === IframeThemeType.light &&
              css`
          background: white;
        `}
  }

  @media screen and (max-width: 1024px) {
    & input {
      padding-left: 45px;
    }
  }
`;
