import { ButtonDownloadSummary, Text, Wrapper } from './Timecodes.styled';

import { convertFromRaw, EditorState } from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';

import { useGetDocsQuery, useLazyGetDocsQuery } from '@/api';
import { FullScreenLoader } from '@/components';
import { useActions } from '@/hooks';
import { Text1 } from '@/pages/OnboardingPage/components/Slide4/styles';
import { StatusType } from '@/types';
import { IframeThemeType } from '@/types/iframeTypes';
import {useIframe} from "@/hooks/useIframe";
// import './styles/customStyles.css';

export const Summary = ({
  hash,
  videoPublicId,
  summaryStatus,
}: {
  hash?: string;
  videoPublicId?: string;
  summaryStatus: StatusType | undefined;
}) => {
  const {iframeTheme, isIframe} = useIframe()
  const { t } = useTranslation('loading');

  const { data, isLoading } = useGetDocsQuery({ videoPublicId: videoPublicId ?? '', hash });

  // const { data: fileFullSummary, isLoading: isFileSummaryLoading } = useGetAllFullSummaryQuery(
  //   { public_id: videoPublicId || '' });

  const { showNotification } = useActions();
  const [getDocs] = useLazyGetDocsQuery();

  const getSummaryHandle = async () => {
    try {
      const summaryLink = await getDocs({
        videoPublicId: videoPublicId || '',
        hash,
      }).unwrap();

      const a = document.createElement('a');
      a.href = summaryLink.pdfFile;
      a.target = '_blanc';
      a.download = `${videoPublicId}.pdf`;

      a.click();
    } catch (e) {
      showNotification({ text: `Не получилось скачать конспект. Попробуйте чуть позже`, severity: 'error' });
    }
  };

  if (summaryStatus === 'NOT_STARTED' && !data) return null;

  return (
    <Wrapper isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
      {isLoading && <FullScreenLoader />}
      {summaryStatus === 'DONE' && data && data.markdown && (
        <>
          <ButtonDownloadSummary isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} onClick={() => getSummaryHandle()}>
            <Text1 isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{t('download_summary')}</Text1>
          </ButtonDownloadSummary>
          <Editor
            editorState={EditorState.createWithContent(convertFromRaw(markdownToDraft(data.markdown || '')))}
            readOnly
            toolbarHidden
            editorStyle={{
              border: '2px solid #E4E4FF',
              borderRadius: '10px',
              padding: '12px 16px 14px 16px',
              marginTop: '20px',
              minHeight: '300px',
              fontSize: '20px',
              fontWeight: 'lighter',
              color: isIframe && iframeTheme === IframeThemeType.light ? '#000046' : '#E4E4FF',
              fontFamily: 'Nunito, sans-serif',
              lineHeight: '130%',
            }}
          />
        </>
      )}

      {(summaryStatus === 'IN_PROGRESS' || summaryStatus === 'FAILED') && (
        <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{t('load_summary')}</Text>
      )}
    </Wrapper>
  );
};
