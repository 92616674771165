import { api } from '@/api';
import { type BaseTimecodesRequest } from '@/types';

const PATH = 'videos';

export const timecodesAdminApi = api.injectEndpoints({
  endpoints: (build) => ({

    updatePartialTimecodesAdmin: build.mutation<void, BaseTimecodesRequest>({
      query: ({ videoPk, publicId, body }) => ({
        url: `${PATH}/${videoPk}/timecodes/${publicId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, { videoPk }) => [{ type: 'control_panel_timecodes', id: videoPk }],
    }),

    deleteTimecodesAdmin: build.mutation<void, Omit<BaseTimecodesRequest, 'body'>>({
      query: ({ videoPk, publicId }) => ({
        url: `${PATH}/${videoPk}/timecodes/${publicId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { videoPk }) => [{ type: 'control_panel_timecodes', id: videoPk }],
    }),
  }),
});

export const { useUpdatePartialTimecodesAdminMutation, useDeleteTimecodesAdminMutation } = timecodesAdminApi;
