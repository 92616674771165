import { QuizVariantWrapper } from './QuizVariant.styled';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckedButton, TrashButton } from '@/components';
import { Button } from '@/components/Shared/Button';
import MyInput from '@/components/Shared/MyInput/MyInput';
import { theme, TitleH5 } from '@/styles';
import { QuizFormState } from '@/types/adminTypes';

export const QuizVariant = ({ questionIndex, index }: { questionIndex: number; index: number }) => {
  const { t } = useTranslation('pages');
  const {
    register,
    formState: { errors },
    clearErrors,
    getValues,
  } = useFormContext<QuizFormState>();

  const { fields, remove, update, append } = useFieldArray<
    QuizFormState,
    `quizzes.${number}.quiz.${number}.answers`,
    'key'
  >({
    name: `quizzes.${index}.quiz.${questionIndex}.answers`,
    keyName: 'key',
    rules: {
      minLength: { value: 2, message: 'minLength' },
      validate: (value) => value.some(({ isCorrect }) => isCorrect),
    },
  });

  const err = errors.quizzes?.[index]?.quiz?.[questionIndex]?.answers?.root?.type;

  const handleToggleCorrect = (ind: number, isCorrect: boolean) => {
    if (!isCorrect) {
      fields.forEach(({ isCorrect: fieldIsCorrect }, i) => {
        if (i !== ind && fieldIsCorrect) {
          update(i, {
            answer: getValues(`quizzes.${index}.quiz.${questionIndex}.answers.${i}.answer`),
            isCorrect: false,
          });
        } else if (i === ind) {
          update(i, {
            answer: getValues(`quizzes.${index}.quiz.${questionIndex}.answers.${i}.answer`),
            isCorrect: true,
          });
        }
      });
      clearErrors(`quizzes.${index}.quiz.${questionIndex}.answers`);
    }
  };

  const handleAddAnswer = () => {
    append({ answer: '', isCorrect: false });
  };

  const handleRemoveAnswer = (value: number) => {
    remove(value);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <TitleH5 key={err} style={{ color: `${err === 'minLength' ? theme.colors.red.red_light : undefined}` }}>{`${t(
        'admin.quiz_variant',
      )} (${fields.length}/4)`}</TitleH5>
      {fields.map(({ key, isCorrect }, ind) => (
        <QuizVariantWrapper key={key}>
          <MyInput
            placeholder=""
            {...register(`quizzes.${index}.quiz.${questionIndex}.answers.${ind}.answer`, { required: true })}
            error={errors.quizzes?.[index]?.quiz?.[questionIndex]?.answers?.[ind]?.answer}
            showErrorMessage={false}
          />
          <CheckedButton
            bg="outline"
            checked={isCorrect}
            disabled={isCorrect}
            isError={err !== 'minLength' && err !== undefined}
            onClick={() => handleToggleCorrect(ind, isCorrect)}
          />
          <div style={{ flex: 1 }}>
            <TrashButton bg="outline" onClick={() => handleRemoveAnswer(ind)} />
          </div>
        </QuizVariantWrapper>
      ))}
      {fields.length < 4 && (
        <Button
          theme="white"
          style={{ padding: '12px 30px', maxWidth: '200px', alignSelf: 'center' }}
          onClick={handleAddAnswer}
        >
          {t('admin.add_answer')}
        </Button>
      )}
    </div>
  );
};
