import { DropDownIMenuItemProps } from './DropDownIMenuItem.props';
import { DropDownMenuItemIcon, DropDownMenuItemStyled } from './DropDownMenuItem.styled';

import { DescriptionText } from '@/styles';

const DropDownMenuItem = ({ onClick, text, icon }: DropDownIMenuItemProps) => {
  return (
    <DropDownMenuItemStyled onClick={onClick}>
      {icon && <DropDownMenuItemIcon>{icon}</DropDownMenuItemIcon>}
      {text && <DescriptionText>{text}</DescriptionText>}
    </DropDownMenuItemStyled>
  );
};

export default DropDownMenuItem;
