/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { NotificationStyled } from './Notification.styled';

import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useActions, useAppSelector } from '@/hooks';

export const Notification = () => {
  const { isShow, text, severity, link } = useAppSelector((state) => state.notification);
  const { closeNotification } = useActions();
  const navigate = useNavigate();

  const goTo = () => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <NotificationStyled
      open={isShow}
      autoHideDuration={5000}
      onClose={() => closeNotification()}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ marginTop: '10%' }}
    >
      <Alert onClose={() => closeNotification()} severity={severity} variant="filled" sx={{ userSelect: 'none' }}>
        {text}
        {link && (
          <span
            style={{ marginLeft: '10px', cursor: 'pointer', textDecoration: 'underline', fontWeight: '600' }}
            onClick={goTo}
          >
            Перейти
          </span>
        )}
      </Alert>
    </NotificationStyled>
  );
};
