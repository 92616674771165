import { apiV2 } from './apiV2';

import { AdminSummary, GetList, QuizApiResponse, VideoFile } from '@/types';

const PATH = 'video-files';

export const videoFilesAPIV2 = apiV2.injectEndpoints({
  endpoints: (build) => ({
    getAllFullSummary: build.query<AdminSummary, { public_id: string }>({
      query: ({ public_id }) => ({
        url: `${PATH}/${public_id}/full-summaries/`,
        method: 'GET',
      }),

      transformResponse: (data: GetList<AdminSummary>) => data.results[0],

      // providesTags: (result, _, { public_id: id }) =>
      //   result
      //     ? [{ type: 'video-file' as const, id }]
      //     : [{ type: 'video-file', id: 'one' }],
    }),

    getAllShortSummary: build.query<GetList<VideoFile>, { public_id: string }>({
      query: ({ public_id }) => ({
        url: `${PATH}/${public_id}/short-summaries/`,
        method: 'GET',
      }),

      // transformResponse: (data: GetList<VideoFile>) => data.results[0],

      // providesTags: (result, _, { public_id: id }) =>
      //   result
      //     ? [{ type: 'video-file' as const, id }]
      //     : [{ type: 'video-file', id: 'one' }],
    }),
    getAllFileQuizzes: build.query<QuizApiResponse, { public_id: string }>({
      query: ({ public_id }) => ({
        url: `${PATH}/${public_id}/quizzes/`,
        method: 'GET',
      }),

      transformResponse: (data: GetList<QuizApiResponse>) => data.results[0],

      // providesTags: (result, _, { public_id: id }) =>
      //   result
      //     ? [{ type: 'video-file' as const, id }]
      //     : [{ type: 'video-file', id: 'one' }],
    }),
  }),
});

export const { useGetAllFullSummaryQuery, useGetAllFileQuizzesQuery, useGetAllShortSummaryQuery } = videoFilesAPIV2;

export const { getAllFileQuizzes } = videoFilesAPIV2.endpoints;
