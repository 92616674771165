import { QuizStyled, Text } from './Quiz.styled';

import { Complete } from '../Complete';
import { Question } from '../Question/Question';

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useGetVideoAllQuizzesQuery } from '@/api';
import FullScreenLoader from '@/components/Shared/FullScreenLo/FullScreenLoader';
import { useAppSelector, useHandlingError } from '@/hooks';
import { StatusType } from '@/types';
import { IframeThemeType } from '@/types/iframeTypes';
import {useIframe} from "@/hooks/useIframe";

interface QuizProps {
  publicId?: string;
  playlistId?: string;
  goToTime: (time: number) => void;
  hash?: string;
  videoPublicId?: string;
  isVideoFile?: boolean;
  playlistType?: string;
  quizStatus?: StatusType | undefined;
}

export const Quiz = ({
  publicId = '',
  playlistId = '',
  goToTime,
  hash,
  videoPublicId = '',
  quizStatus,
}: QuizProps) => {
  const {iframeTheme, isIframe} = useIframe()

  const { t } = useTranslation('loading');

  const { data, isLoading, isError, error } = useGetVideoAllQuizzesQuery(
    { playlistId, videoPublicId, hash },
    { skip: Boolean(publicId) },
  );

  const [activeQuestionIndex, questions, done] = useAppSelector((state) => [
    state.quiz.activeQuestionIndex,
    state.quiz.questions,
    state.quiz.done,
  ]);

  const { catchError } = useHandlingError();

  useEffect(() => {
    catchError(error);
  }, [error]);

  if (isError || quizStatus === 'NOT_STARTED') {
    return null;
  }

  return (
    <QuizStyled isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
      {isLoading && <FullScreenLoader />}
      {quizStatus === 'FAILED' && <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{t('load_quiz')}</Text>}
      {data && !done && (
        <Question
          isIframe={isIframe}
          iframeTheme={iframeTheme as IframeThemeType}
          {...questions[activeQuestionIndex]}
          goToTime={goToTime}
        />
      )}
      {/*{(quizStatus === "IN_PROGRESS" || quizStatus === "FAILED") && <Text iframeTheme={iframeTheme as IframeThemeType}>{t('load_quiz')}</Text>}*/}
      {/*{quizStatus === 'DONE' && data && !done && <Question iframeTheme={iframeTheme as IframeThemeType} {...questions[activeQuestionIndex]} goToTime={goToTime} />}*/}
      {done && <Complete isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} />}
    </QuizStyled>
  );
};
