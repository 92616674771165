import { useTranslation } from 'react-i18next';

import { Button } from '@/components';
import { API_SHARE_URL } from '@/constants';
import { useActions } from '@/hooks';
import {useIframe} from "@/hooks/useIframe";

export type CopyIframeButtonPropsType = {
  playlistId?: string;
  videoId?: string;
};

export const CopyIframeButton = ({ playlistId, videoId }: CopyIframeButtonPropsType) => {
  const { t } = useTranslation('components');
  const { showNotification } = useActions();
  const {iframeTheme, iframeLang, vendorId, showTest} = useIframe()

  const copyIframe = async () => {
    let iframe = '';
    try {
      if (videoId) {
        iframe = `<iframe height="auto" src='${API_SHARE_URL}/embed/videos/${videoId}?t=0&vendor=${vendorId}&theme=${iframeTheme}&lang=${iframeLang}&show_test=${showTest}'></iframe>`;
      } else if (playlistId) {
        iframe = `<iframe height="auto" src='${API_SHARE_URL}/embed/playlist/${playlistId}?vendor=${vendorId}&theme=${iframeTheme}&lang=${iframeLang}&show_test=${showTest}'></iframe>`;
      }
      await navigator.clipboard.writeText(iframe);
      showNotification({
        text: 'Встраиваемый код скопирован!',
        severity: 'success',
      });
    } catch (error) {
      showNotification({
        text: t('link', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  return (
    <Button theme="black" style={{ height: '100%' }} onClick={copyIframe}>
      {t('copyIframe')}
    </Button>
  );
};
