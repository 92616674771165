import { TitleWithButtonWrapper, Wrapper } from '../AdminPage.styled';
import { GoBack } from '../AdminVideoOutlet/components';

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { playlistsAPI } from '@/api';
import { FullScreenLoader, RemoveVideo } from '@/components';
import { VideoCard } from '@/components/Card';
import { useHandlingError } from '@/hooks';
import { CardItem, CardList, HeaderTitle, InfoTitle, InfoWrapper } from '@/pages/LibraryPage';

export const PlayListPageAdmin = () => {
  const { t } = useTranslation('pages');
  const { playlistId } = useParams();
  const { catchError } = useHandlingError();
  const {
    data: playlist,
    isLoading,
    isSuccess,
    error,
  } = playlistsAPI.useGetPlaylistByIdQuery({ id: playlistId || '' }, { skip: !playlistId });

  useEffect(() => {
    catchError(error);
  }, [error]);

  return (
    <Wrapper>
      <GoBack />
      <TitleWithButtonWrapper>
        <HeaderTitle>{playlist?.title} </HeaderTitle>
        {/*<Button theme="white">Редактировать квиз</Button>*/}
      </TitleWithButtonWrapper>
      <CardList>
        {isSuccess &&
          playlist.videos.map((movie) => (
            <CardItem key={movie.publicId}>
              <VideoCard movie={movie} to={`/account/edit/${playlistId}/${movie.publicId}/timecodes`} menu />
            </CardItem>
          ))}
      </CardList>
      {isSuccess && playlist.videos.length === 0 && (
        <InfoWrapper>
          <InfoTitle>{t('library.no_v')}</InfoTitle>
        </InfoWrapper>
      )}
      {isLoading && <FullScreenLoader />}
      <RemoveVideo />
    </Wrapper>
  );
};
