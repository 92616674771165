import {
  ButtonsWrapper,
  FormAddPlaylistVideo,
  Label,
  VideoImage,
  VideosList,
  VideosListItem,
  VideosWrapper,
  InputContent,
  VideoTitle,
  LabelBox,
  CheckBox,
  SecondaryTextAddPlaylistVideo,
  ErrorTextWrapperAddVideoPl,
} from './AddPlayListVideo.styled';

import Button from '../../Buttons/Button/Button';
import { SuggestVideoButton } from '../../SuggestVideoButton';
import { Inner, Modal, Title } from '../Modal';

import { ChangeEvent, FormEvent, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { videosAPI, playlistsAPI } from '@/api';
import videofilePrevios from '@/components/Card/VideoCard/images/videofile-previos.png';
import Input from '@/components/Shared/Input/Input';
import { LinkVK, LinkYT, TextError } from '@/components/Shared/Modals/AddVideo/AddVideo.styled';
import { useActions, useAppSelector } from '@/hooks';
import { DescriptionText } from '@/styles';
import { Playlist } from '@/types';
import { combinedRegex, vkRegex, vkVideoRegex, youtubeRegex } from '@/utils/regExSoursesLink';

interface AddPlayListVideoModalProps {
  playlist: Playlist;
}

export const AddPlaylistVideo = ({ playlist }: AddPlayListVideoModalProps) => {
  const [addedVideoItems, setAddedVideoItems] = useState<string[]>([]);
  const { t } = useTranslation('modal');
  const { closeModal, showNotification } = useActions();
  const modal = useAppSelector((state) => state.modal.active);
  const [videoLinkValue, setVideoLinkValue] = useState<string>('');
  const [isLinkError, setIsLinkError] = useState(false);
  const [isError, setIsError] = useState(false);

  const { data: videos } = videosAPI.useGetMyVideosQuery(
    { params: { page: 1, pageSize: 999 } },
    { skip: modal !== 'AddPlayListVideo' },
  );
  const [addToPlayList] = playlistsAPI.useAddVideoToPlaylistMutation();
  const [createMovie] = videosAPI.useCreateVideoMutation();

  const closeHandler = () => {
    closeModal();
    setAddedVideoItems([]);
    setIsError(false);
    setIsLinkError(false);
  };

  const addToPlaylist = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLinkError(false);

    if (addedVideoItems.length) {
      const payload = await addToPlayList({
        playlistId: playlist.publicId,
        videos: addedVideoItems.map((it) => ({ isAiSuggested: false, videoPublicId: it })),
      });
      if ('data' in payload) {
        closeHandler();
        showNotification({
          text: t('add_videos_pl_n', { ns: 'success', name: playlist.title }),
          severity: 'success',
        });
      } else {
        console.log(payload);
      }
    } else if (videoLinkValue) {
      const newVideoId = await createMovie({ originLink: videoLinkValue });

      if ('data' in newVideoId) {
        const payload = await addToPlayList({
          playlistId: playlist.publicId,
          videos: [{ isAiSuggested: false, videoPublicId: newVideoId.data.publicId }],
        });

        if ('data' in payload) {
          closeHandler();
          showNotification({
            text: t('add_video_pl', { ns: 'success' }),
            severity: 'success',
          });
        } else {
          showNotification({
            text: t('error', { ns: 'error' }),
            severity: 'error',
          });
        }
      }
    } else if (!addedVideoItems.length && !videoLinkValue) {
      showNotification({
        text: t('add_v_not_sel', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  const addVideo = (id: string) => {
    const array = Object.assign([], addedVideoItems);
    if (array.includes(id)) {
      const index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
        setAddedVideoItems(array);
      }
    } else {
      array.push(id);
      setAddedVideoItems(array);
    }
  };

  const videoLinkHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.trim();
    setVideoLinkValue(trimmedValue);
    setIsError(false);
    setIsLinkError(false);

    const isYouTube = youtubeRegex.test(trimmedValue);
    const isVK = vkRegex.test(trimmedValue);
    const isVKVideo = vkVideoRegex.test(trimmedValue);
    // const isKinescope = kinescopeRegex.test(trimmedValue);
    const isValidResourceLink = combinedRegex.test(trimmedValue);

    if (isValidResourceLink) {
      if (!isYouTube && !isVK && !isVKVideo) {
        setIsError(true);
        setIsLinkError(false);
      }
    } else {
      setIsLinkError(true);
      setIsError(false);
    }
  };

  return (
    <Modal modal={'AddPlayListVideo'} closeButton onClose={closeHandler}>
      <Title>{t('add_v_to_pl')}</Title>
      <Inner modal={'AddPlayListVideo'}>
        <FormAddPlaylistVideo onSubmit={addToPlaylist}>
          <Label>
            <SecondaryTextAddPlaylistVideo>{t('add_v_to_pl_insert')}</SecondaryTextAddPlaylistVideo>
            <VideosWrapper>
              {videos?.results.length === 0 && <DescriptionText>{t('add_v_to_pl_no_v')}</DescriptionText>}
              <VideosList>
                {videos &&
                  videos.results
                    .filter((video) => !playlist.videos.map((movie) => movie.publicId).includes(video.publicId))
                    .map((video, index) => (
                      <VideosListItem
                        key={index}
                        onClick={() => {
                          addVideo(video.publicId);
                        }}
                      >
                        <LabelBox>
                          <InputContent>
                            {video.source === 'YOUTUBE' ? (
                              <VideoImage src={`https://i.ytimg.com/vi/${video.videoId}/maxresdefault.jpg`} />
                            ) : video.source === 'UPLOADED' ? (
                              <VideoImage src={videofilePrevios} />
                            ) : (
                              <VideoImage src={video.thumbnailUrl} />
                            )}

                            <VideoTitle>{video.title}</VideoTitle>
                          </InputContent>
                        </LabelBox>
                        <CheckBox defaultChecked={addedVideoItems.includes(video.publicId)} />
                      </VideosListItem>
                    ))}
              </VideosList>
            </VideosWrapper>
          </Label>
          <div style={{ marginBottom: '10px' }}>
            <SecondaryTextAddPlaylistVideo style={{ marginBottom: '10px' }}>
              {t('video_insert_add_v')}
            </SecondaryTextAddPlaylistVideo>
            <Input
              style={{
                borderColor:
                  (isError && videoLinkValue !== '') || (isLinkError && videoLinkValue !== '') ? '#EF7A80' : '',
                paddingRight: '38px',
              }}
              placeholder={t('video_insert')}
              value={videoLinkValue}
              onChange={videoLinkHandler}
            />
          </div>
          {isError && videoLinkValue ? (
            <ErrorTextWrapperAddVideoPl>
              <TextError>{t('incorrect_video_link', { ns: 'error' })}</TextError>
              <TextError>{t('incorrect_video_link1', { ns: 'error' })}</TextError>
            </ErrorTextWrapperAddVideoPl>
          ) : null}
          {isLinkError && videoLinkValue ? (
            <ErrorTextWrapperAddVideoPl>
              <TextError>{t('incorrect_video_link_tiktok', { ns: 'error' })}</TextError>
              <TextError>
                {t('incorrect_video_link_tiktok1', { ns: 'error' })}
                <LinkVK to={'https://vk.com/video'}>VK, </LinkVK>
                <LinkYT to={'https://www.youtube.com'}>YouTube </LinkYT>
              </TextError>
            </ErrorTextWrapperAddVideoPl>
          ) : null}
          <SuggestVideoButton
            previouslySuggestedVideos={playlist.listAiSuggestedVideoPks}
            publicId={playlist.publicId}
          />
          <ButtonsWrapper>
            <Button
              theme="white"
              type="submit"
              disabled={Boolean((isError && videoLinkValue) || (isLinkError && videoLinkValue))}
            >
              {t('form.save', { ns: 'components' })}
            </Button>
            <Button onClick={closeHandler} theme="inline">
              {t('form.cancel', { ns: 'components' })}
            </Button>
          </ButtonsWrapper>
        </FormAddPlaylistVideo>
      </Inner>
    </Modal>
  );
};
