import {normalize} from './normalize';
import {theme} from './theme';

import {createGlobalStyle, css} from 'styled-components';
import {IframeThemeType} from "@/types/iframeTypes";

interface GlobalStyleProps {
  isIframe?: boolean;
  iframeTheme?: IframeThemeType
}

export default createGlobalStyle<GlobalStyleProps>`
    
  body {
      font-family: ${theme.fonts.nunito}, sans-serif;
      color: ${theme.colors.text.white_100};
      background-color: #0B002C;
      background-image: url("/images/blur-ellipses.svg");
      background-size: cover;
      hyphens: auto;
      overflow-wrap: anywhere;
      position: relative;

      width: 100%;

      ::-webkit-scrollbar {
          display: ${({isIframe}) => (isIframe ? 'none' : '')};
          width: 10px; /* ширина scrollbar */
      }

      ::-webkit-scrollbar-track {
          background: transparent; /* цвет дорожки */
      }

      ::-webkit-scrollbar-thumb {
          border-radius: 20px; /* закругления плашки */
          background-color: ${theme.colors.blue.blue_5}; /* цвет плашки */
      }

      ${({isIframe, iframeTheme}) =>
              isIframe && iframeTheme === IframeThemeType.light &&
              css`
                  background-color: white;
            `}

      /* scrollbar-gutter: stable; */
      /* @media (max-width : 768px){
  
      ::-webkit-scrollbar {
        display: none;
      } */

    /* } */
  }

  /* normalize */
  ${normalize}
`;
