import Avatar from '../../Shared/Avatar';
// import CommentsIcon from '../../SvgIcons/CommentsIcon';
// import EyeIcon from '../../SvgIcons/EyeIcon';
// import LikeIcon from '../../SvgIcons/LikeIcon';
import {
  ImageWrapper,
  // StatisticsList,
  // StatisticsItem,
  Inner,
  Title,
  SubTitle,
  VideoQuantity,
  UserInfo,
  UserName,
} from '../PlaylistCard';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { UserPlaylistCardProps } from '@/components/Card';
import { LockIcon } from '@/components/SvgIcons';

export const UserPlaylistCard = ({ playlist, name, avatar, isAdmin }: UserPlaylistCardProps) => {
  const {
    title,
    description,
    publicId: id,
    videos,
    category: { image },
  } = playlist;

  const { t } = useTranslation('components');
  const location = useLocation();

  return (
    <div>
      <Link to={isAdmin ? `/account/edit/${id}` : `/playlist/${id}`} state={{ location: location.pathname }}>
        {/* <Link to={`/playlist/${id}?u=${owner.id}`} state={{ location: location.pathname }}> */}
        <ImageWrapper bgImage={image ?? '/images/playlist_mock.png'}>
          <UserInfo>
            <Avatar name={name} size="xs" url={avatar} auth />
            <UserName>{name}</UserName>
            {playlist.privacyType === 'private' && <LockIcon theme="outline" />}
          </UserInfo>
          <VideoQuantity>{`${videos.length} ${t('video', { count: videos.length })}`}</VideoQuantity>
        </ImageWrapper>
        <Inner>
          {/* <StatisticsList>
            <StatisticsItem>
              {likes_count} <LikeIcon />
            </StatisticsItem>
            <StatisticsItem>
              {comments_count} <CommentsIcon />
            </StatisticsItem>
            <StatisticsItem>
              {views_count} <EyeIcon />
            </StatisticsItem>
          </StatisticsList> */}
          <Title>{title.substring(0, 100)}</Title>
          <SubTitle>{description.substring(0, 100)}</SubTitle>
        </Inner>
      </Link>
    </div>
  );
};
