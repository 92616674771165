/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  AddVideoText,
  AddVideoText1,
  AddVideoWrapper,
  ButtonsWrapper,
  ErrorTextWrapper,
  Form,
  InfoBlockAddVideo,
  Container,
  LinkContact,
  LinkVK,
  LinkYT,
  SecondaryTextAddVideo,
  SecondaryTextAddVideoWrapper,
  SecondaryTextErrorFormat,
  SecondaryTextInfoBlock,
  TextError,
  VideoFileBlock,
  VideoFileErrorBlock,
} from './AddVideo.styled';

import Button from '../../Buttons/Button/Button';
import FullScreenLoader from '../../FullScreenLo/FullScreenLoader';
import Input from '../../Input/Input';
import { Inner, Modal, Title } from '../Modal';

import Select from 'react-select';

import { AddVideoIcon } from '@/components/SvgIcons/AddVideoIcon';
import clearInputIcon from '@/components/SvgIcons/clearInputIcon.svg';
import iconLock from '@/components/SvgIcons/lock-icon-select.svg';
import { UploadProgressAddVideoFile } from '@/components/UploadProgress/UploadProgressAddVideoFile';
import { useAddVideo } from '@/hooks/useAddVideo';
import { InfoText } from '@/pages/AdminPage/AdminPage.styled';
import { SecondaryText, TextCenter } from '@/styles';

export const AddVideo = () => {
  const {
    videoLinkValue,
    selectedPlaylist,
    isLinkError,
    isError,
    selectedFile,
    setSelectedFile,
    isLoading,
    videoLinkHandler,
    addVideo,
    closeHandler,
    deleteVideo,
    setSelectedPlaylist,
    handleDrop,
    handleDragOver,
    onFileChange,
    t,
    setVideoLinkValue,
    fileInputRef,
    incorrectVideoFormat,
    result,
    stylesSelect,
    playlists,
    options,
    isCommercial,
    presignedUrl,
    setPresignedUrl,
    userId,
    setIsError,
    isSuccess,
    setIsSuccess,
    isAborted,
    setIsAborted,
    setShowInfo,
    showInfo,
  } = useAddVideo();

  const noPlaylistOption = [
    {
      value: 'no_playlists',
      label: 'У вас пока нет плейлистов',
      isDisabled: true,
    },
  ];

  // eslint-disable-next-line react/display-name
  const formatOptionLabel = () => (data: any) =>
    (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>{data.label}</span>
        {data.isPrivate && <img style={{ marginLeft: '10px' }} src={iconLock} alt="iconLock" />}
      </div>
    );

  const disabledSaveButton = videoLinkValue === '' && isLoading;

  const selectedPlaylistDetails = playlists?.results.find((playlist) => playlist.publicId === selectedPlaylist);

  return (
    <Modal modal={'AddVideo'} onClose={closeHandler} closeButton>
      <Title>{t('addVideo', { ns: 'header' })}</Title>
      <Inner modal={'AddVideo'}>
        <Form onSubmit={addVideo}>
          {disabledSaveButton && <FullScreenLoader />}
          <Container style={{ position: 'relative' }}>
            <Input
              style={{
                borderColor:
                  (isError && videoLinkValue !== '') || (isLinkError && videoLinkValue !== '') ? '#EF7A80' : '',
                paddingRight: '38px',
              }}
              placeholder={t('video_insert')}
              value={videoLinkValue}
              onChange={videoLinkHandler}
              disabled={selectedFile !== null}
              onMouseOver={() => setShowInfo(false)}
            />
            {videoLinkValue !== '' && (
              <img
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: `${!isError && !isLinkError ? '35%' : '20%'}`,
                  right: '15px',
                }}
                onClick={() => setVideoLinkValue('')}
                src={clearInputIcon}
                alt="clearInputIcon"
              />
            )}
            {isError && videoLinkValue !== '' ? (
              <ErrorTextWrapper>
                <TextError>{t('incorrect_video_link', { ns: 'error' })}</TextError>
                <TextError>{t('incorrect_video_link1', { ns: 'error' })}</TextError>
              </ErrorTextWrapper>
            ) : null}

            {isLinkError && videoLinkValue !== '' ? (
              <ErrorTextWrapper>
                <TextError>{t('incorrect_video_link_tiktok', { ns: 'error' })}</TextError>
                <TextError>
                  {t('incorrect_video_link_tiktok1', { ns: 'error' })}
                  <LinkVK to={'https://vk.com/video'}>VK, </LinkVK>
                  <LinkYT to={'https://www.youtube.com'}>YouTube </LinkYT>
                  {/*{t('incorrect_video_link_tiktok2', {ns: 'error'})}*/}
                  {/*<Link onMouseEnter={() => setHover(3)}*/}
                  {/*      onMouseLeave={() => setHover(0)}*/}
                  {/*      style={{textDecoration: "underline", color: hover === 3 ? '#62548B' : '#EF7A80'}}*/}
                  {/*      to={'https://kinescope.io'}>Kinescope</Link>*/}
                </TextError>
              </ErrorTextWrapper>
            ) : null}
          </Container>
          {!selectedFile && <TextCenter>{t('or_add_video')}</TextCenter>}
          <input
            id="file1"
            type="file"
            onChange={(e) => onFileChange(e)}
            ref={fileInputRef}
            hidden
            accept="video/mp4" //audio/ogg, video/avi,
            disabled={!isCommercial}
          />
          <Container>
            {!selectedFile || isError ? (
              <AddVideoWrapper
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                videoLinkValue={videoLinkValue}
                isCommercial={isCommercial}
              >
                <div style={{ display: 'flex', justifyContent: 'center', opacity: '0.5' }}>
                  <AddVideoIcon />
                </div>
                <div style={{ position: 'relative' }}>
                  <label htmlFor="file1">
                    <AddVideoText1 onMouseOver={() => setShowInfo(true)}>{t('add_video_text1')}</AddVideoText1>
                  </label>
                  <AddVideoText onMouseOver={() => setShowInfo(true)}>{t('add_video_text2')}</AddVideoText>
                </div>
                <SecondaryTextAddVideoWrapper>
                  <SecondaryTextAddVideo>{t('add_video_insert_text3')}</SecondaryTextAddVideo>
                </SecondaryTextAddVideoWrapper>
              </AddVideoWrapper>
            ) : (
              <UploadProgressAddVideoFile
                presignedUrl={presignedUrl}
                setPresignedUrl={setPresignedUrl}
                setSelectedFile={setSelectedFile}
                userId={userId}
                selectedFile={selectedFile}
                deleteVideo={deleteVideo}
                setIsError={setIsError}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                setIsAborted={setIsAborted}
              />
            )}
            {incorrectVideoFormat && (
              <VideoFileErrorBlock>
                <SecondaryTextErrorFormat>{t('video_format_invalid1')}</SecondaryTextErrorFormat>
                <SecondaryTextErrorFormat>{t('video_format_invalid2')}</SecondaryTextErrorFormat>
              </VideoFileErrorBlock>
            )}
          </Container>
          {!isCommercial && showInfo && (
            <InfoBlockAddVideo>
              <InfoText>
                {t('video_info_commercial_1') + t('video_info_commercial_2')}
                <LinkContact to={'mailto:contact@visaver.com'}>contact@visaver.com</LinkContact>
              </InfoText>
            </InfoBlockAddVideo>
          )}
          {!selectedFile && (
            <Container>
              <SecondaryText
                style={{ color: `${!videoLinkValue ? '#62548B' : ''}`, opacity: !videoLinkValue ? '0.4' : '1' }}
              >
                {t('add_video_text3')}
              </SecondaryText>

              <Container style={{ position: 'relative', width: '100%', opacity: !videoLinkValue ? '0.4' : '1' }}>
                <Select
                  options={options.length === 0 ? noPlaylistOption : options}
                  isDisabled={!videoLinkValue}
                  onChange={(selectedOption) => setSelectedPlaylist(selectedOption?.value || '')}
                  placeholder={t('video_no_select')}
                  menuPortalTarget={document.body}
                  formatOptionLabel={formatOptionLabel()}
                  styles={stylesSelect}
                />
                {videoLinkValue &&
                  !isError &&
                  !isLinkError &&
                  selectedPlaylist &&
                  selectedPlaylistDetails?.privacyType !== 'private' && (
                    <div style={{ marginTop: '5px' }}>
                      <VideoFileBlock>
                        <SecondaryTextInfoBlock>{t('video_info_pl_1')}</SecondaryTextInfoBlock>
                        <SecondaryTextInfoBlock>{t('video_info_pl_2')}</SecondaryTextInfoBlock>
                      </VideoFileBlock>
                    </div>
                  )}
              </Container>
            </Container>
          )}
          <ButtonsWrapper onMouseOver={() => setShowInfo(false)}>
            <Button
              disabled={disabledSaveButton || (selectedFile !== null && !isSuccess && !isError && !isAborted)}
              theme="white"
              type="submit"
            >
              {t('form.save', { ns: 'components' })}
            </Button>
            <Button onClick={closeHandler} theme="inline">
              {t('form.cancel', { ns: 'components' })}
            </Button>
          </ButtonsWrapper>
          {result.isLoading && <FullScreenLoader />}
        </Form>
      </Inner>
    </Modal>
  );
};
