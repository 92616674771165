import { QuizBlock } from './components/QuizBlock';
import { QuizBlockWrapper, QuizPageStyledForm } from './EditQuizPage.styled';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePatchQuizzesAdminMutation } from '@/api';
import { FullScreenLoader, RemoveQuiz } from '@/components';
import { Button } from '@/components/Shared/Button';
import CrossIcon from '@/components/SvgIcons/CrossIcon';
import { useActions, useHandlingError } from '@/hooks';
import { theme } from '@/styles';
import { QuizApiResponse, QuizFormState } from '@/types';
import { parseDuration } from '@/utils';
import { Buttons } from '@/pages/AdminPage/EditTimecodesPage/EditTimecodesPage.styled';

const transformToQuizFetchedData = ({ quizzes }: QuizFormState): Pick<QuizApiResponse, 'data'> => ({
  data: quizzes.map(({ chapter, quiz }) => ({
    chapter: { ...chapter, start: parseDuration(chapter.start as string) },
    quiz: quiz.map(({ question, answers }) => ({
      question,
      correctAnswer: answers.find(({ isCorrect }) => isCorrect)?.answer || '',
      wrongAnswers: answers.filter((answer) => !answer.isCorrect).map(({ answer }) => answer),
    })),
  })),
});

export const EditQuizForm = ({
  defaultValues,
  goToTime,
  videoId,
}: {
  defaultValues: QuizFormState;
  goToTime: (value: number | string) => void;
  videoId: string;
}) => {
  const { t } = useTranslation('pages');

  const { playlistId } = useParams();
  const { openModal, showNotification } = useActions();
  const [updateQuiz, { isLoading: updateStatus }] = usePatchQuizzesAdminMutation();

  const { catchError } = useHandlingError();

  const methods = useForm<QuizFormState>({ defaultValues });

  const handleSubmitQuiz = async (value: QuizFormState) => {
    try {
      await updateQuiz({
        videoId,
        publicId: defaultValues.publicId,
        body: transformToQuizFetchedData(value),
      }).unwrap();

      showNotification({
        text: t('save_quiz', { ns: 'success' }),
        severity: 'success',
      });

      methods.reset();
    } catch (err) {
      catchError(err, t('save_quiz_err', { ns: 'error' }));
    }
  };

  const handleDeleteQuiz = () => {
    openModal({ active: 'RemoveQuiz' });
  };

  const { fields, remove, append } = useFieldArray<QuizFormState, 'quizzes', 'key'>({
    name: 'quizzes',
    keyName: 'key',
    control: methods.control,
  });

  const handleAddQuestionBlock = () => {
    append({
      chapter: { text: '', title: '', start: '' },
      quiz: [
        {
          question: '',
          answers: [
            { answer: '', isCorrect: false },
            { answer: '', isCorrect: false },
          ],
        },
      ],
    });
  };

  return (
    <FormProvider {...methods}>
      <QuizPageStyledForm onSubmit={methods.handleSubmit(handleSubmitQuiz)}>
        {fields.map(({ key }, index) => (
          <QuizBlockWrapper key={key}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
              {/* <TitleH4>{t('admin.title')}</TitleH4> */}
              <Button
                theme="transparent"
                style={{
                  padding: '4px 8px',
                  color: theme.colors.text.white_80,
                }}
                variant="withIcon"
                onClick={() => remove(index)}
              >
                <CrossIcon />
              </Button>
            </div>
            <QuizBlock index={index} goToTime={goToTime} />
          </QuizBlockWrapper>
        ))}
        <Button
          style={{ padding: '12px 30px' }}
          variant="outlined"
          theme="inline"
          onClick={handleAddQuestionBlock}
          disabled={updateStatus}
        >
          {t('admin.add_section')}
        </Button>
        {fields.length > 0 && (
          <Buttons>
            <Button
              type="submit"
              theme="white"
              disabled={updateStatus || (!methods.formState.isDirty && methods.formState.submitCount === 0)}
            >
              {t('admin.save')}
            </Button>
            <Button style={{ padding: '12px 30px' }} onClick={handleDeleteQuiz} disabled={updateStatus}>
              {t('admin.delete')}
            </Button>
          </Buttons>
        )}
        {updateStatus && <FullScreenLoader />}
      </QuizPageStyledForm>
      <RemoveQuiz playlistId={playlistId || ''} videoId={videoId || ''} publicId={defaultValues.publicId || ''} />
    </FormProvider>
  );
};
