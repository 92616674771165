export function formatDuration(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsRemaining = seconds % 60;

  return `${padZero(hours)}:${padZero(minutes)}:${padZero(secondsRemaining)}`;
}

function padZero(value: number) {
  return (value < 10 ? '0' : '') + value;
}
