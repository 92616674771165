import { SearchResultInput } from './SearchResultPage.styled';

import SearchIcon from '../../../components/SvgIcons/SearchIcon';
import { Inner } from '../../MainPage/MainPage.styled';
import { ResultInner } from '../components/ResultInner';
import { SearchWrapper } from '../Search.styled';

import { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { videosAPI } from '@/api';
import { useDebounce } from '@/hooks';

export const SearchResultPage = () => {
  const { t } = useTranslation('pages');
  const [params, setParams] = useSearchParams();
  const search = useRef<HTMLInputElement | null>(null);

  const { data, isFetching, isLoading, isSuccess } = videosAPI.useGetVideosQuery(
    {
      params: {
        title: search.current?.value || params.get('search') || '',
        pageSize: 100,
      },
    },
    { skip: !params.get('search') },
  );

  const makeSearch = useDebounce(() => {
    const data = search.current?.value || '';
    if (data) {
      setParams({ search: data });
    } else {
      setParams({});
    }
  }, 500);

  const onSearch = () => {
    makeSearch();
  };

  return (
    <>
      <SearchWrapper>
        <SearchResultInput
          icon={<SearchIcon />}
          ref={search}
          onChange={onSearch}
          defaultValue={params.get('search') ?? ''}
          placeholder={t('search.input_placeholder')}
        />
      </SearchWrapper>
      <Inner>
        {isSuccess && <ResultInner data={data.results} isLoading={isLoading || isFetching} countVideo={data?.count} />}
      </Inner>
    </>
  );
};

export default SearchResultPage;
