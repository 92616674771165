import { CardItem, CardList, InfoTitle, InfoWrapper } from '../LibraryPage';

import { useEffect } from 'react';

import { Pagination } from '@mui/material';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { useGetMyVideosQuery } from '@/api';
import { FullScreenLoader, RemoveVideo } from '@/components';
import { VideoCard } from '@/components/Card';
import { useHandlingError, usePagination } from '@/hooks';
import { PaginationWrapper } from '@/styles';

export const AdminVideos = () => {
  const { t } = useTranslation('pages');
  const { catchError } = useHandlingError();

  const {
    pagination: { pages, page },
    handleChangePage,
    setCount,
  } = usePagination();

  const { data: videos, isLoading, isSuccess, error } = useGetMyVideosQuery({ params: { page } });

  useEffect(() => {
    if (isSuccess) {
      setCount(videos.results.length === 0 ? 1 : videos.count);
    }
  }, [videos]);

  useEffect(() => {
    catchError(error);
  }, [error]);

  // const setSearch = async (title?: string) => {
  //   await getMyVideos({ params: { title } });
  // };

  return (
    <>
      {/* <SearchInput placeholder={t('admin.search.video')} setSearch={setSearch} /> */}
      <CardList>
        {isSuccess &&
          videos.results.map((movie) => (
            <CardItem key={movie.publicId}>
              <VideoCard movie={movie} to={`watch/${movie.publicId}`} menu />
            </CardItem>
          ))}
      </CardList>
      {isSuccess && videos.results.length === 0 && (
        <InfoWrapper>
          <InfoTitle>{t('library.no_v')}</InfoTitle>
        </InfoWrapper>
      )}
      {videos && videos.results.length > 0 && (
        <PaginationWrapper>
          {pages != 1 && (
            <Pagination
              count={pages}
              page={page}
              size={isDesktop ? 'medium' : 'small'}
              color="primary"
              onChange={handleChangePage}
            />
          )}
        </PaginationWrapper>
      )}
      {isLoading && <FullScreenLoader />}
      <RemoveVideo />
    </>
  );
};
