import styled, { css } from 'styled-components';

import { ContainerStyled } from '@/components/Shared/Container/Container.styled';
import { FooterText, theme } from '@/styles';

export const FooterStyled = styled.footer`
  background: #0b012c;
  z-index: 1;
`;

export const FooterContainer = styled(ContainerStyled)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 5px 0;
  gap: 77px;

  @media screen and (max-width: 1000px) {
    gap: 70px;
    justify-content: space-evenly;
  }
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media screen and (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
  }
`;
export const Copy = styled(FooterText)``;
export const InfoTitle = styled.h3`
  width: 297px;
  color: #dad3ff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;

  @media screen and (max-width: 1000px) {
    text-align: center;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
`;
export const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  & a {
    color: ${theme.colors.white.white_30};
    opacity: 0.8;
    text-align: end;
  }

  @media (max-width: 480px) {
    font-size: 11px;
  }
`;

export const InfoSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1225px) {
    justify-content: center;
  }

  & > p {
    display: flex;
    width: 297px;
    flex-direction: column;
    flex-shrink: 0;
    color: #dad3ff;
    font-size: 18px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    text-transform: uppercase;
  }
`;

export const TargetsSection = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;
export const TargetsItem = styled.div`
  display: flex;
  gap: 26px;
`;
export const TargetsText = styled.div`
  max-width: 201px;
  color: #8d7af2;
  font-size: 16px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
`;
interface IMAGE {
  image: string;
}
export const InfoImage = styled.div<IMAGE>`
  width: 48px;
  height: 48px;
  ${(props) => css`
    background: url(${props.image}) no-repeat center;
  `}
`;
