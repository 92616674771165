import { ButtonsWrapper, Inner } from './RemovePlaylist.styled';

import { Modal, Title } from '../Modal';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { playlistsAPI } from '@/api';
import { FullScreenLoader, Button } from '@/components';
import { useActions } from '@/hooks';

interface RemovePlaylistProps {
  publicId: string;
}
export const RemovePlaylist = ({ publicId }: RemovePlaylistProps) => {
  const { t } = useTranslation('components');
  const { closeModal, showNotification } = useActions();
  const navigate = useNavigate();
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [deletePlaylist, result] = playlistsAPI.useDeletePlaylistMutation();

  const deletePL = () => {
    const result = deletePlaylist({ publicId });
    if ('error' in result) {
      showNotification({
        text: t('del_pl', { ns: 'error' }),
        severity: 'error',
      });
      setIsDelete(true);
    } else {
      closeModal();
      navigate(-1);
      showNotification({
        text: t('del_pl', { ns: 'success' }),
        severity: 'success',
      });
    }
  };

  return (
    <Modal modal={'RemovePlaylist'} closeButton>
      <Inner>
        <Title>{t('del_pl', { ns: 'modal' })}</Title>
        {/* <SubTitle>Все видео сохраняться в разделе «Ваши видео»</SubTitle> */}
        <ButtonsWrapper>
          <Button theme={isDelete ? 'disabled' : 'red'} onClick={deletePL} disabled={isDelete}>
            {t('form.del')}
          </Button>
          <Button onClick={() => closeModal()} theme="inline">
            {t('form.cancel')}
          </Button>
        </ButtonsWrapper>
        {result.isLoading && <FullScreenLoader />}
      </Inner>
    </Modal>
  );
};
