import { QuizAnswersWrapper, QuizBlockWrapper, QuizTitleEditWrapper } from './QuizBlock.styled';

import { QuizVariant } from '../QuizVariant';

import { useState } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHookFormMask } from 'use-mask-input';

import { Button, TrashButton } from '@/components';
import MyInput from '@/components/Shared/MyInput/MyInput';
import PlayIcon from '@/components/SvgIcons/PlayIcon';
import PlusBigIconBlack from '@/components/SvgIcons/PlusBigIconBlack';
import { TitleTimeWrapper } from '@/pages/AdminPage/EditTimecodesPage/components/TitleAndTime/TitleTime.styled';
import { TitleH5 } from '@/styles';
import { QuizFormState } from '@/types/adminTypes';

export const QuizBlock = ({ index, goToTime }: { index: number; goToTime: (value: number | string) => void }) => {
  const { t } = useTranslation('pages');
  const [mouseOver, setOnMouseOver] = useState(false);

  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext<QuizFormState>();

  const registerWithMask = useHookFormMask(register);

  const { fields, append, remove } = useFieldArray<QuizFormState, `quizzes.${number}.quiz`, 'key'>({
    name: `quizzes.${index}.quiz`,
    keyName: 'key',
  });

  return (
    <QuizBlockWrapper>
      <TitleTimeWrapper>
        <MyInput
          {...register(`quizzes.${index}.chapter.title`, { required: true })}
          placeholder={t('admin.timecodes_title')}
          error={errors.quizzes?.[index]?.chapter?.title}
          showErrorMessage={false}
        />
        <div style={{ display: 'flex', flexShrink: 0, width: '110px' }}>
          <MyInput
            {...registerWithMask(`quizzes.${index}.chapter.start`, '99:99:99', {
              required: true,
              pattern: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/,
              validate: (value) => index === 0 || Boolean(index && value !== '00:00:00'),
            })}
            placeholder="00:00:00"
            style={{ padding: '12px 16px', overflow: 'hidden' }}
            error={errors.quizzes?.[index]?.chapter?.start}
            showErrorMessage={false}
          />
        </div>
        <Button
          style={
            mouseOver
              ? { padding: '10px', borderColor: '#BBB6DA', borderWidth: '2px', transition: '0.3s linear' }
              : { padding: '10px', borderColor: '#62548B' }
          }
          onMouseOut={() => setOnMouseOver(false)}
          onMouseOver={() => setOnMouseOver(true)}
          variant="withIcon"
          theme="inline"
          onClick={() => goToTime(getValues(`quizzes.${index}.chapter.start`))}
        >
          <PlayIcon width="24" height="24" fill={!mouseOver ? '#62548B' : '#BBB6DA'} />
        </Button>
      </TitleTimeWrapper>
      {fields.map(({ key }, ind) => (
        <QuizBlockWrapper key={key}>
          <TitleH5>{t('admin.question')}</TitleH5>

          <QuizTitleEditWrapper>
            <div style={{ width: '100%' }}>
              <MyInput
                {...register(`quizzes.${index}.quiz.${ind}.question`, { required: true })}
                error={errors.quizzes?.[index]?.quiz?.[ind]?.question}
                showErrorMessage={false}
              />
            </div>

            {fields.length > 1 && (
              <div style={{ flex: 1 }}>
                <TrashButton bg="outline" onClick={() => remove(ind)} />
              </div>
            )}
          </QuizTitleEditWrapper>

          <QuizAnswersWrapper>
            <QuizVariant questionIndex={ind} index={index} />
          </QuizAnswersWrapper>
        </QuizBlockWrapper>
      ))}
      <Button
        theme="white"
        style={{ padding: '13px', maxWidth: '50px', alignSelf: 'center  ' }}
        onClick={() =>
          append({
            question: '',
            answers: [
              { answer: '', isCorrect: false },
              { answer: '', isCorrect: false },
              { answer: '', isCorrect: false },
              { answer: '', isCorrect: false },
            ],
          })
        }
      >
        <PlusBigIconBlack />
      </Button>
    </QuizBlockWrapper>
  );
};
