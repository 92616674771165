/* eslint-disable @typescript-eslint/no-explicit-any */
import { TargetImage, TargetsStyled, TargetsItem, TargetsText } from './Targets.styled.';

import { Component } from 'react';

import { type WithTranslation, withTranslation } from 'react-i18next';
import Slider from 'react-slick';

class Targets extends Component<IProps, IState> {
  render() {
    const settings = {
      className: 'targets',
      dots: true,
      arrows: false,
      accessibility: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      fade: true,
      speed: 500,
      cssEase: 'linear',
    };
    const t = this.props.t;
    return (
      <TargetsStyled>
        <Slider {...settings}>
          <div>
            <TargetsItem>
              <TargetImage image="/images/target-1.svg" />
              <TargetsText>{t('search.footer.target')}</TargetsText>
            </TargetsItem>
          </div>
          {/*<div>*/}
          {/*  <TargetsItem>*/}
          {/*    <TargetImage image="/images/target-2.svg" />*/}
          {/*    <TargetsText>{t('search.footer.target2')}</TargetsText>*/}
          {/*  </TargetsItem>*/}
          {/*</div>*/}
          <div>
            <TargetsItem>
              <TargetImage image="/images/video-1.svg" />
              <TargetsText>{t('search.footer.target3')}</TargetsText>
            </TargetsItem>
          </div>
        </Slider>
      </TargetsStyled>
    );
  }
}

export default withTranslation('pages')(Targets);

interface IProps extends WithTranslation {
  prop: any;
}

interface IState {
  state: any;
}
