const DoneLoading = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.31077 11.2755C1.71093 10.8948 2.34389 10.9106 2.72455 11.3108L9.38337 18.3108C9.76402 18.7109 9.74821 19.3439 9.34805 19.7245C8.9479 20.1052 8.31493 20.0894 7.93428 19.6892L1.27546 12.6892C0.894808 12.2891 0.910618 11.6561 1.31077 11.2755Z"
        fill="#14AE5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6899 4.27607C23.0897 4.65707 23.1049 5.29005 22.7239 5.68987L9.38275 19.6899C9.00175 20.0897 8.36877 20.1049 7.96895 19.7239C7.56913 19.3429 7.55388 18.71 7.93488 18.3101L21.2761 4.31013C21.6571 3.91032 22.29 3.89506 22.6899 4.27607Z"
        fill="#14AE5C"
      />
    </svg>
  );
};

export default DoneLoading;
