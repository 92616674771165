import { ButtonText } from '../../../../styles';
import { Button } from '../Button';

import styled from 'styled-components';

export const GoHomeButtonStyled = styled(Button)`
  padding: 0;
`;

export const BackLink = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const BackLinkText = styled(ButtonText)``;
