import styled from 'styled-components';

import { ContainerStyled } from '@/components/Shared/Container/Container.styled';

export const HeaderStyled = styled.header``;
export const HeaderContainer = styled(ContainerStyled)``;

export const Inner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
  button {
    padding: 5px;
  }
`;
