import { TextWrapper, Text, TextLink } from './Timecodes.styled';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { IframeThemeType } from '@/types/iframeTypes';

export const TimecodeDiscription = ({ text, iframeTheme, isIframe }: { text: string; iframeTheme: IframeThemeType, isIframe?: boolean }) => {
  const { t } = useTranslation('components');
  const [isCollapsedText, setIsCollapsedText] = useState(true);

  const onReadMoreClick = () => {
    setIsCollapsedText((p) => !p);
  };

  if (text.length < 100)
    return (
      <TextWrapper isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
        <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{text}</Text>
      </TextWrapper>
    );

  return (
    <TextWrapper isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
      {isCollapsedText ? (
        <span>
          <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{`${text.slice(0, 100)}...`}</Text>
          <TextLink isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} onClick={onReadMoreClick}>
            {t('timecodes_desc_more')}
          </TextLink>
        </span>
      ) : (
        <span>
          <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{text}</Text>
          <TextLink isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} onClick={onReadMoreClick}>
            {t('timecodes_button')}
          </TextLink>
        </span>
      )}
    </TextWrapper>
  );
};
