import {
  VideoCardWrapper,
  InnerCard,
  // VideoTime,
  PlayButton,
  Title,
  FragmentTime,
  ImageFragmentWrapper,
} from './VideoFragmentCard.styled';

import PlayIcon from '../../SvgIcons/PlayIcon';

import { memo } from 'react';

import { VideoFragmentCardProps } from '@/components/Card';
import { IframeThemeType } from '@/types/iframeTypes';
import { getImageUrl, secondsToTime } from '@/utils';


const VideoFragmentCard = ({
                             fragment: {content, timestampLink /* image*/},
                             goToTime,
                             videoPreview,
                             iframeTheme,
                             isIframe
                           }: VideoFragmentCardProps) => {
  const startsFrom = parseInt(timestampLink);

  const goToHandler = () => {
    goToTime?.(startsFrom);
  };

  const getBackgroundImage = () => {
    // TODO вернуть когда починят для фреймов
    // if (image) {
    //   return getImageUrl(image);
    // }
    return videoPreview ? getImageUrl(videoPreview) : '/images/playlist_mock.png';
  };

  return (
    <VideoCardWrapper onClick={goToHandler}>
      <ImageFragmentWrapper bgImage={getBackgroundImage()}>
        <PlayButton>
          <PlayIcon />
        </PlayButton>
        {/* <VideoTime>
          {new Date(startsFrom * 1000).toLocaleTimeString('ru-RU', {
            second: '2-digit',
            minute: '2-digit',
          })}
        </VideoTime> */}
      </ImageFragmentWrapper>
      {/* <DescriptionMenuWrapper>
          </DescriptionMenuWrapper> */}
      <InnerCard>
        <FragmentTime>{secondsToTime(startsFrom)}</FragmentTime>
        <Title isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} dangerouslySetInnerHTML={{ __html: content }} />
      </InnerCard>
    </VideoCardWrapper>
  );
};

export default memo(VideoFragmentCard);
