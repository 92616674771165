import { DropDownMenuProps } from './DropDownMenu.props';

import { theme } from '@/styles';

import styled, {css} from 'styled-components';
import {IframeThemeType} from "@/types/iframeTypes";

export const Wrapper = styled.div<Pick<DropDownMenuProps, 'open'> & { iframeTheme?: IframeThemeType }>`
  position: absolute;
  top: 100%;
  right: 0;
  padding: 16px 0;
  min-width: 280px;
  background: ${theme.colors.blue.blue_5};
  box-shadow: 0px 0px 24px rgba(31, 30, 254, 0.2);
  border-radius: 10px;
  z-index: 1;
  transform: translateY(8px);
  animation: ${(props) => (props.open ? 'visible' : 'hide')} 0.3s forwards;

  @keyframes visible {
    0% {
      opacity: 0;
      top: 50%;
      display: none;
    }
    100% {
      opacity: 1;
      top: 100%;
      display: block;
    }
  }
  @keyframes hide {
    100% {
      opacity: 0;
      top: 50%;
      display: none;
    }
  }

    ${({iframeTheme}) =>
            iframeTheme === IframeThemeType.light &&
            css`
                background: rgb(228, 228, 255);
            `}
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.li<{iframeTheme: IframeThemeType}>`
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: transparent;
  transition: background 0.3s ease;
  :hover {
    background: ${theme.colors.blue.blue_20};
  }
  :active {
    background: ${theme.colors.blue.blue_55};
  }

    ${({iframeTheme}) =>
            iframeTheme === IframeThemeType.light &&
            css`
                :hover {
                    background: rgba(190, 190, 241, 0.64);
                }

                :active {
                    background: rgb(228, 228, 255);
                }
            `}
`;
