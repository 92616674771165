import { Wrapper } from './EditSummaryPage.styled';
import { SummaryEditor } from './SummaryEditor';
import { convertFromRaw } from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';
import { useParams } from 'react-router-dom';

import { FullScreenLoader } from '@/components';

import './styles/customStyles.css';

import { Title } from '@/pages/AdminPage/AdminPage.styled';
import { useTranslation } from 'react-i18next';
import { summariesAdminApi } from '@/api/videosApi/summariesAdminApi';


export const EditSummaryPage = () => {
  const { videoId } = useParams();
  const { t } = useTranslation('loading');

  const { data, isLoading } = summariesAdminApi.useGetAllSummariesAdminQuery(
    { videoId: videoId ?? '' },
    {
      skip: !videoId,
    },
  );

  if (data?.results.length === 0) {
    return <Title>{t('load_summary')}</Title>;
  }

  return (
    <Wrapper>
      {isLoading && <FullScreenLoader />}

      {data && data?.results.length > 0 && (
        <SummaryEditor
          contentState={convertFromRaw(markdownToDraft(data.results[0]?.markdown || ''))}
          publicId={data.results[0]?.publicId}
        />
      )}
    </Wrapper>
  );
};
