import { memo, useEffect, useState } from 'react';

import { Box, LinearProgress } from '@mui/material';
import axios, { type AxiosProgressEvent } from 'axios';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import DoneLoading from '@/components/SvgIcons/DoneLoading';
import TrashButtonModal from '@/components/SvgIcons/TrashButtonModal';
import { useActions } from '@/hooks';
import { LabelText, SecondaryText } from '@/styles';

type UploadProgressPropsType = {
  presignedUrl: string;
  selectedFile: File;
  deleteVideo: () => void;
  userId: string;
  setIsError: (value: boolean) => void;
  setPresignedUrl: (value: string) => void;
  setSelectedFile: (value: any) => void;
  isSuccess: boolean;
  setIsSuccess: (value: boolean) => void;
  setIsAborted: (value: boolean) => void;
};

export const UploadProgressAddVideoFile = memo(
  ({
    presignedUrl,
    selectedFile,
    deleteVideo,
    setIsError,
    setPresignedUrl,
    setSelectedFile,
    isSuccess,
    setIsSuccess,
    setIsAborted,
    userId,
  }: UploadProgressPropsType) => {
    const { showNotification } = useActions();
    const [progress, setProgress] = useState<number>(0);
    const { t } = useTranslation('modal');
    const [abortController, setAbortController] = useState<AbortController | null>(null);

    useEffect(() => {
      const uploadFile = async () => {
        if (!presignedUrl || !selectedFile || !userId) return;
        setProgress(0);
        setIsAborted(false);
        const controller = new AbortController();

        setAbortController(controller);

        try {
          await axios.put(presignedUrl, selectedFile, {
            headers: {
              'x-amz-meta-userId': userId,
            },
            signal: controller.signal,
            onUploadProgress: (event: AxiosProgressEvent) => {
              if (event.total) {
                const percentCompleted = Math.round((event.loaded * 100) / event.total);
                setProgress(percentCompleted);
              }
            },
          });

          setIsSuccess(true);
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Upload canceled:', error.message);
          } else {
            console.error('Upload failed:', error);
            showNotification({
              text: t('error', { ns: 'error' }),
              severity: 'error',
            });
            setIsSuccess(false);
            setProgress(0);
            setIsError(true);
          }
        }
      };

      if (presignedUrl && selectedFile && userId) {
        uploadFile();
      }
    }, [presignedUrl, selectedFile, userId]);

    const handleCancelUpload = () => {
      if (abortController) {
        abortController.abort();
        setIsSuccess(false);
        setIsAborted(true);
        setSelectedFile('');
        setPresignedUrl('');
        setProgress(0);
      }
    };

    return (
      <div>
        {!isSuccess && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px', marginTop: '8px' }}>
            <Box sx={{ width: '100%' }}>
              <StyledLinearProgress variant="determinate" value={progress} />
            </Box>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <SecondaryText>{`${Math.round(progress)}%` + t('download_file_status')}</SecondaryText>
              <SecondaryTextStyled
                style={{
                  textDecoration: 'underline',
                }}
                onClick={handleCancelUpload}
              >
                {t('download_file_skip')}
              </SecondaryTextStyled>
            </div>
          </Box>
        )}
        {isSuccess && progress === 100 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '8px',
              marginBottom: '8px',
            }}
          >
            <div style={{ marginRight: '10px', display: 'flex', gap: '10px' }}>
              <DoneLoading />
              <LabelText>{selectedFile.name}</LabelText>
            </div>
            <div>
              <TrashButtonModal onClick={deleteVideo} />
            </div>
          </div>
        )}
      </div>
    );
  },
);

UploadProgressAddVideoFile.displayName = 'UploadProgressAddVideoFile';

const StyledLinearProgress = styled(LinearProgress)(() => ({
  borderRadius: '5px',
  height: '7px !important',
  '& .MuiLinearProgress-bar': {
    borderRadius: '5px',
  },
}));

const SecondaryTextStyled = styled(SecondaryText)(() => ({
  ':hover': {
    cursor: 'pointer',
    color: '#E4E4FF',
  },
}));
