// import { Text, TitleH3, CardsList } from '../../styles/components';

import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;
