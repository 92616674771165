import VkIcon from '@/components/SvgIcons/VkIcon';
import YouTubeIcon from '@/components/SvgIcons/YouTubeIcon';

export const API_SHARE_URL = import.meta.env.VITE_BASE_URL;
export const ACCESS_TOKEN = 'access';
export const REFRESH_TOKEN = 'refresh';
export const ONBOARDING = 'onboarding';
export const GET_IMAGE = (category: string | number) =>
  `${import.meta.env.VITE_MEDIA_URL}/photos/backgrounds/${category}.jpg`;
// `${import.meta.env.VITE_MEDIA_URL}/media/photos/backgrounds/${category}.jpg`;

export const SOURCES_DATA = [
  { link: 'https://www.youtube.com/', icon: <YouTubeIcon /> },
  // {link: 'https://www.tiktok.com/'},
  { link: 'https://vk.com/video', icon: <VkIcon /> },
  // {link: 'https://kinescope.io/'}
];
