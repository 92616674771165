import styled, {css} from 'styled-components';
import {IframeThemeType} from "@/types/iframeTypes";

export const ContainerStyled = styled.div<{iframeTheme?: IframeThemeType}>`
  width: 100%;
  max-width: 1344px;
  margin: 0 auto;
  padding: 0 32px;
  height: 100%;
  /* position: relative; */

  @media screen and (max-width: 1024px) {
    max-width: 1088px;
  }
  @media screen and (max-width: 768px) {
    max-width: 1008px;
    padding: 0 20px;

      ${({iframeTheme}) =>
              iframeTheme &&
              css`
                padding: 10px;
            `}
  }
  @media screen and (max-width: 480px) {
    max-width: 520px;
  }
  @media screen and (max-width: 360px) {
    max-width: 400px;
  }
`;
