import {useLocation, useSearchParams} from "react-router-dom";

export const useIframe = () => {
  const location = useLocation();
  const [params] = useSearchParams();

  const isIframe = location.pathname.includes('embed');
  const iframeTheme = params.get('theme') ?? 'light';
  const iframeLang = params.get('lang') ?? 'ru';
  const vendorId = params.get('vendor') ?? '';
  const showTest = params.get('show_test') ?? '0';

  return {
    isIframe,
    iframeTheme,
    iframeLang,
    vendorId,
    showTest
  }
}