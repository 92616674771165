import { useEffect } from 'react';

export const useIframeHeight = () => {
  useEffect(() => {
    const sendHeight = () => {
      const rootElement = document.getElementById('root');
      if (rootElement) {
        const height = rootElement.offsetHeight;
        window.parent.postMessage({ frameHeight: height }, '*');
      }
    };

    const onDomReady = () => {
      sendHeight();

      window.addEventListener('resize', sendHeight);

      const rootElement = document.getElementById('root');
      if (rootElement) {
        const resizeObserver = new ResizeObserver(sendHeight);
        resizeObserver.observe(rootElement);

        return () => {
          resizeObserver.disconnect();
          window.removeEventListener('resize', sendHeight);
        };
      }
    };

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', onDomReady);
    } else {
      onDomReady();
    }
  }, []);
};