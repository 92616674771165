import styled, { css } from 'styled-components';

import { theme, TitleH4 } from '@/styles';
import { IframeThemeType } from '@/types/iframeTypes';

export const CompleteStyled = styled.div`
  display: flex;
  min-height: 300px;

  align-items: center;
  justify-content: center;
`;

export const CompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

export const Title = styled(TitleH4)<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  font-weight: 700;
  text-transform: uppercase;

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 568px) {
    button {
      width: 100%;
    }
  }
`;
