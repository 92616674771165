import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { InfoBlock } from '@/pages/AdminPage/AdminPage.styled';
import { SecondaryText, theme } from '@/styles';

interface AddVideoWrapperProps {
  videoLinkValue?: string;
  isCommercial: boolean;
}

export const Form = styled.form`
  position: relative;
  width: 436px;

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 10px;
`;

export const ButtonsWrapper = styled.a`
  display: flex;
  gap: 10px;
`;

export const AddVideoWrapper = styled.div<AddVideoWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  border: 1px dashed #62548b;
  border-radius: 10px;
  padding: 30px;
  cursor: ${({ videoLinkValue }) => (videoLinkValue !== '' ? 'default' : 'pointer')};
  cursor: ${({ isCommercial }) => (!isCommercial ? 'default' : 'pointer')};
  opacity: ${({ videoLinkValue }) => (videoLinkValue !== '' ? 0.5 : '')};
  opacity: ${({ isCommercial }) => (!isCommercial ? 0.5 : '')};

  &:hover {
    background-color: transparent;
  }

  ${({ videoLinkValue, isCommercial }) =>
    videoLinkValue === '' &&
    isCommercial &&
    `
        &:hover {
            background-color: #3E1F86;
        }
    `}
`;
export const AddVideoText = styled.span`
  font-weight: 700;
  font-family: Nunito, sans-serif;
  font-size: 17px;
  line-height: 24px;
  color: #e4e4ff;
  width: max-content;

  @media (max-width: 1028px) {
    font-size: 15px;
  }
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;
export const AddVideoText1 = styled(AddVideoText)`
  text-decoration: underline;
  cursor: pointer;
`;

export const SecondaryTextAddVideo = styled(SecondaryText)`
  text-align: center;
  width: max-content;
  font-size: 13px;
  line-height: 16px;
`;

export const SecondaryTextErrorFormat = styled(SecondaryText)`
  font-size: 13px;
  line-height: 16px;
  color: #000046;
`;

export const SecondaryTextInfoBlock = styled(SecondaryText)`
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  text-align: center;
`;
export const VideoFileErrorBlock = styled.div`
  background-color: #ef7a80;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VideoFileBlock = styled.div`
  background-color: #1f1efe;
  border-radius: 10px;
  padding: 8px 5px;
  margin-top: 6px;
  margin-bottom: 13px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const InfoBlockAddVideo = styled(InfoBlock)`
  top: 50%;
  left: 22px;
  width: 90%;
`;

export const SecondaryTextAddVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SecondaryTextSelect = styled(SecondaryText)`
  width: max-content;
  color: #62548b;
`;

export const ErrorTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const TextError = styled.span`
  color: #ef7a80;
  font-size: 13px;
  font-weight: 400;
`;

export const LinkContact = styled(Link)`
  text-decoration: underline;
  color: ${theme.colors.text.white_80};
  :hover {
    color: #e4e4ff;
  }
`;

export const LinkVK = styled(Link)`
  text-decoration: underline;
  color: #ef7a80;
  :hover {
    color: #62548b;
  }
`;

export const LinkYT = styled(LinkVK)`
  margin-left: 5px;
  margin-right: 2px;
`;
