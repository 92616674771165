import {getMinutes} from './helpers';
import {TimecodeDiscription} from './TimecodeDiscription';
import {
  List,
  ListItem,
  Text,
  Time,
  TimecodesContentWrapper,
  TimecodesTitle,
  TimecodesTitleWrapper,
  TimecodesWrapper,
} from './Timecodes.styled';

import {memo, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {useGetTimecodesQuery} from '@/api';
import {useHandlingError} from '@/hooks';
import {StatusType} from '@/types';
import {IframeThemeType} from '@/types/iframeTypes';
import {useIframe} from "@/hooks/useIframe";
import {FullScreenLoader} from "@/components";


interface TimecodesProps {
  setTime: (time: number) => void;
  id: string;
  currentTime?: number | null;
  hash?: string;
  timecodesStatus: StatusType | undefined;
}

export const Timecodes = memo(({ setTime, id, currentTime, hash, timecodesStatus }: TimecodesProps) => {
  const { t } = useTranslation('error');
  const { catchError } = useHandlingError();
  const {isIframe, iframeTheme} = useIframe()

  // const [curTime, setCurTime] = useState(currentTime);

  const { data, isLoading, isError, error } = useGetTimecodesQuery({ videoPublicId: id ?? '', hash });

  // const {
  //   data: summaries,
  //   isError: isSummariesError,
  //   isLoading: isSummariesLoading,
  // } = useGetAllShortSummaryQuery({public_id: id ?? ''});

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [error]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const timecodeTitleRef = useRef<HTMLHeadingElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const timings = useMemo(() => data?.map((array) => array.start as number) ?? [], [data]);

  useEffect(() => {
    if (listRef.current) {
      const children = Array.from(listRef.current.children) as HTMLLIElement[];
      children.forEach((child, index) => {
        if (currentTime && Math.round(currentTime) === timings[index]) {
          wrapperRef.current?.scrollTo({ top: child.offsetTop, behavior: 'smooth' });
        }

        if (
          currentTime &&
          currentTime >= timings[index] &&
          (timings[index + 1] === undefined || currentTime < timings[index + 1]) &&
          timecodeTitleRef.current
        ) {
          timecodeTitleRef.current.style.backgroundColor = '#BE9F4B';
        } else {
          timecodeTitleRef.current && (timecodeTitleRef.current.style.backgroundColor = 'transparent');
        }
      });
    }
  }, [currentTime]);

  if (timecodesStatus === 'NOT_STARTED') {
    return null;
  }

  const isTarget = (index: number) =>
    Boolean(
      currentTime &&
        currentTime >= timings[index] &&
        (timings[index + 1] === undefined || currentTime < timings[index + 1]),
    );

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {data && (
        <TimecodesWrapper ref={wrapperRef} isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
          <TimecodesContentWrapper>
            {isError && (
              <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{t('load_timecodes', { ns: 'loading' })}</Text>
            )}

            {(timecodesStatus === 'IN_PROGRESS' || timecodesStatus === 'FAILED') && data.length === 0 && (
              <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{t('load_timecodes', { ns: 'loading' })}</Text>
            )}

            {data && data.length > 0 && (
              <>
                {/* <Title>{t('timecodes', { ns: 'components' })}</Title> */}
                <List isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} ref={listRef}>
                  {data.map(({ start, text, title }, index) => (
                    <ListItem isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} key={index}>
                      <TimecodesTitleWrapper isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} onClick={() => setTime(start as number)}>
                        <Time isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{getMinutes(start as number)}</Time>
                        <TimecodesTitle isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} isTarget={isTarget(index)}>
                          {title}
                        </TimecodesTitle>
                      </TimecodesTitleWrapper>
                      <TimecodeDiscription isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} text={text} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </TimecodesContentWrapper>
        </TimecodesWrapper>
      )}
    </>
  );
});

Timecodes.displayName = 'Timecodes';
