import { CardItem, CardList, InfoTitle, InfoWrapper } from './LibraryPage.styled';

import { useEffect } from 'react';

import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { playlistsAPI } from '@/api';
import { FullScreenLoader } from '@/components';
import { UserPlaylistCard } from '@/components/Card';
import { usePagination, useMatchMedia, useAppSelector, useHandlingError } from '@/hooks';
import { PaginationWrapper } from '@/styles';

export const PlaylistsWrapper = () => {
  const { t } = useTranslation('pages');
  const { username, avatar } = useAppSelector((state) => state.user);
  const { catchError } = useHandlingError();
  const {
    pagination: { pages, page },
    handleChangePage,
    setCount,
  } = usePagination();

  const isDesktop = useMatchMedia('(min-width: 1025px)');

  const {
    data: playlists,
    isLoading,
    isSuccess,
    isFetching,
    error,
  } = playlistsAPI.useGetMyPlaylistsQuery({ params: { page } });

  useEffect(() => {
    if (isSuccess) {
      setCount(playlists.results.length === 0 ? 1 : playlists.count);
    }
    if (error) {
      catchError(error);
      console.log(error);
    }
  }, [playlists, error]);

  return (
    <>
      {isSuccess && (
        <>
          <CardList>
            {playlists.results.map((item, i) => (
              <CardItem key={i}>
                <UserPlaylistCard playlist={item} name={username.slice(0, 10)} avatar={avatar} />
              </CardItem>
            ))}
          </CardList>
          {playlists.results.length > 0 && (
            <PaginationWrapper>
              {pages != 1 && (
                <Pagination
                  count={pages}
                  page={page}
                  size={isDesktop ? 'medium' : 'small'}
                  color="primary"
                  onChange={handleChangePage}
                />
              )}
            </PaginationWrapper>
          )}
        </>
      )}
      {playlists?.results.length === 0 && (
        <InfoWrapper>
          <InfoTitle>{t('library.no_pl')}</InfoTitle>
        </InfoWrapper>
      )}
      {(isLoading || isFetching) && <FullScreenLoader />}
    </>
  );
};
