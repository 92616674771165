import styled, { css } from 'styled-components';

import { theme } from '@/styles';
import { IframeThemeType } from '@/types/iframeTypes';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 576px) {
    margin-top: 13vh;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    gap: 50px;
    padding-left: 8vw;
    padding-right: 3vw;
  }

  @media (min-width: 1200px) {
    //gap: 240px;
    gap: 0;
    padding-left: 18vw;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 576px) {
    padding-left: 40px;
    width: 450px;
  }

  @media (min-width: 992px) {
    width: 494px;
    gap: 40px;
  }

  @media (min-width: 1200px) {
    padding-left: 0;
    width: 478px;
  }
`;

export const TextHeader = styled.h1`
  font-family: ${theme.fonts.nunito};
  font-size: 25px;
  font-weight: 700;
  color: ${theme.colors.white.white_100};

  @media (min-width: 992px) {
    font-size: 44px;
  }

  @media (min-width: 1400px) {
    font-size: 52px;
  }
`;

export const Text1 = styled.span<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  font-family: ${theme.fonts.nunito};
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: ${theme.colors.label.label_light};

  @media (min-width: 992px) {
    font-size: 21px;
  }

  @media (min-width: 1400px) {
    font-size: 26px;
  }

  ${({ iframeTheme, isIframe }) =>
   isIframe && iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const Text3 = styled.p`
  font-family: ${theme.fonts.nunito};
  font-size: 16px;
  font-weight: 400;
  line-height: 170%;
  color: ${theme.colors.label.label_light};

  @media (min-width: 992px) {
    font-size: 21px;
  }

  @media (min-width: 1400px) {
    font-size: 26px;
  }
`;
export const Step4Image = styled.img`
  max-width: 254px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;

  @media (min-width: 576px) {
    margin-top: -18%;
  }

  @media (min-width: 768px) {
    margin-top: -10%;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
    margin-top: -89px;
    width: 422px;
    height: 554px;
  }
`;
export const TextGreen = styled.span`
  font-family: ${theme.fonts.nunito};
  font-size: 16px;
  font-weight: 800;
  line-height: 170%;
  color: ${theme.colors.green.green_light};
  padding-right: 3px;
  padding-left: 3px;

  @media (min-width: 992px) {
    font-size: 21px;
    padding-right: 5px;
    padding-left: 5px;
  }

  @media (min-width: 1400px) {
    font-size: 26px;
  }
`;

export const TextBlock = styled.p`
  font-family: ${theme.fonts.nunito};
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: ${theme.colors.label.label_light};

  @media (min-width: 992px) {
    font-size: 21px;
  }

  @media (min-width: 1400px) {
    font-size: 26px;
  }
`;

export const ImageSlide = styled.img`
  width: 500px;
  max-width: 100%;

  @media (max-width: 1920px) {
  }

  @media (max-width: 576px) {
    width: 100%;
    height: 100%;
    flex: unset;
  }
`;

export const TextFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px !important;
  max-width: 50%;
  justify-content: space-between;

  @media (max-width: 576px) {
    width: 100% !important;
    max-width: unset;
  }
`;
