import { forwardRef, useImperativeHandle, useRef, useCallback } from 'react';

import ReactPlayer from 'react-player';
import { useSearchParams } from 'react-router-dom';

export const FileVideoPlayer = forwardRef<
  { goToTime: (time: number) => void },
  { url: string; startsFrom?: number; height: string; setCurrentTime?: (time: number) => void }
>(({ url, height, startsFrom, setCurrentTime }, ref) => {
  const iframe = useRef<ReactPlayer>(null);
  const [searchParams] = useSearchParams();
  const hasInitialized = useRef(false);

  const goToTimeFunc = useCallback(async (time: number) => {
    if (iframe.current) {
      iframe.current.seekTo(time, 'seconds');
      const player = iframe.current.getInternalPlayer();
      if (player?.play) {
        player.play();
      }
    }
  }, []);

  const onPlayerReady = useCallback(() => {
    if (hasInitialized.current) return;

    hasInitialized.current = true;

    const tParam = searchParams.get('t');
    const startTime = tParam ? parseInt(tParam.replace(/\D/g, ''), 10) : startsFrom;

    if (startTime !== undefined && !isNaN(startTime)) {
      goToTimeFunc(startTime);
    }
  }, [searchParams, startsFrom, goToTimeFunc]);

  useImperativeHandle(
    ref,
    () => ({
      goToTime: goToTimeFunc,
    }),
    [goToTimeFunc],
  );

  return (
    <ReactPlayer
      ref={iframe}
      url={url}
      playing={true}
      controls
      width="100%"
      height={height}
      progressInterval={1000}
      onReady={onPlayerReady}
      onProgress={({ playedSeconds }) => {
        setCurrentTime?.(playedSeconds);
      }}
    />
  );
});

FileVideoPlayer.displayName = 'FileVideoPlayer';
