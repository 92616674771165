import { Wrapper } from './CheckedButtonStyled';

import TickIcon from '../../../SvgIcons/TickIcon';

import { MouseEvent } from 'react';

interface CheckedButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  bg?: 'red' | 'black' | 'white' | 'none' | 'outline';
  checked?: boolean;
  disabled?: boolean;
  isError?: boolean;
}

export const CheckedButton = ({
  bg = 'black',
  onClick,
  checked = false,
  isError = false,
  disabled = false,
}: CheckedButtonProps) => {
  return (
    <Wrapper
      onClick={onClick}
      type="button"
      variant="withIcon"
      theme={bg}
      checked={checked}
      disabled={disabled}
      isError={isError}
    >
      <TickIcon />
    </Wrapper>
  );
};
