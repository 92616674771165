import styled, { css } from 'styled-components';

import { Button } from '@/components';
import { ButtonText, theme } from '@/styles';
import { IframeThemeType } from '@/types/iframeTypes';

type VideoWrapperPropsType = {
  iframeTheme?: IframeThemeType;
};

export const ContainerVideoPage = styled.div<{iframeTheme: IframeThemeType}>`
  padding: 20px 0;

    ${({iframeTheme}) =>
            iframeTheme &&
            css`
                padding: 0;
            `}
`;

export const Wrapper = styled.div``;
export const Title = styled.p<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
  color: ${theme.colors.text.white_100};

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 28px;
    //margin-left: 20px;
  }

  ${({ iframeTheme, isIframe }) =>
   isIframe && iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const Inner = styled.div`
  //margin: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const VideoWrapper = styled.div<VideoWrapperPropsType>`
  width: 100%;
  /* height: 500px; */
  background-color: ${theme.colors.blue.blue_5};
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('https://catherineasquithgallery.com/uploads/posts/2021-02/1612753659_156-p-fon-gradient-goluboi-fioletovii-180.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 4px 75px 50px rgba(11, 0, 44, 0.49);

  ${({ iframeTheme }) =>
    iframeTheme === IframeThemeType.light &&
    css`
      box-shadow: none;
    `}
`;

export const TabWrapper = styled.div<{ disabled: boolean; iframeTheme: IframeThemeType, isIframe?: boolean }>`
  display: flex;
  border-radius: 12px;
  border: ${({ disabled }) => (disabled ? '2px #685FA7 solid' : '2px white solid')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  @media screen and (max-width: 768px) {
    width: 100%;
  }

    ${({iframeTheme, isIframe}) =>
            isIframe && iframeTheme === IframeThemeType.light &&
            css`
                background: rgba(60, 64, 67, 0.07);
            `}
`;

export const ButtonTab = styled(Button)<{ disabled: boolean }>`
  @media screen and (max-width: 768px) {
    padding: 10px 0;
    flex: 1;
  }
`;

export const Panel = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  justify-items: flex-end;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PanelButtons = styled.div`
  display: flex;
  flex: 1;

  gap: 10px;
  justify-content: space-between;
  justify-items: flex-end;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const VideoPageInputWrapper = styled.div`
  flex: 1;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

`;

export const InfoBlockPlaylistMaterial = styled.div<{ iframeTheme?: IframeThemeType, isIframe?: boolean }>`
  position: absolute;
  z-index: 1;
  background-color: #16066c;
  border-radius: 10px;
  max-width: 500px;
  padding: 20px;
  right: 30px;

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      background-color: rgb(228, 228, 255);
    `}
`;

export const TextTab = styled(ButtonText)<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  ${({ iframeTheme, isIframe }) =>
   isIframe && iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark} !important;
    `}
`;

export const CardList = styled.div`
  position: relative;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(289px, 1fr));
  gap: 20px;

  @media screen and (max-width: 768px) {
    justify-items: center;
  }
`;
