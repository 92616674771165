import {
  AnswersWrapper,
  ControlButton,
  ControlWrapper,
  QuestionBody,
  QuestionHeader,
  QuestionWrapper,
  SeekToButton,
  Title,
  TitleWrapper,
  VisibleAnswer,
} from './Question.styled';

import { useTranslation } from 'react-i18next';

import { ArrowLeft } from '@/components/SvgIcons';
import ArrowRight from '@/components/SvgIcons/ArrowRight';
import { useActions, useAppSelector } from '@/hooks';
import { IframeThemeType } from '@/types/iframeTypes';

interface QuestionProps {
  question: string;
  answers: string[];
  correctAnswer: string;
  answer: string | null;
  goToTime: (time: number) => void;
  start: number | string;
  iframeTheme: IframeThemeType;
  isIframe: boolean
}

export const Question = ({
                           isIframe,
                           iframeTheme,
                           answers,
                           correctAnswer,
                           answer: currentAnswer,
                           question,
                           goToTime,
                           start,
}: QuestionProps) => {
  const { t } = useTranslation('components');
  const { setQuizAnswer, setActiveQuestionIndex, setQuizDone } = useActions();
  const [activeQuestionIndex, questions] = useAppSelector((state) => [
    state.quiz.activeQuestionIndex,
    state.quiz.questions,
  ]);
  // const isLastQuestion = activeQuestionIndex === questions.length - 1;

  const handleAnswer = (answer: string) => {
    if (!currentAnswer) {
      setQuizAnswer({ question, answer });
    }
  };

  const onNextButtonHandler = () => {
    if (!questions.filter((q) => q.answer === null).length) {
      setQuizDone();
    }
    questions.length > activeQuestionIndex + 1 && setActiveQuestionIndex(activeQuestionIndex + 1);
  };

  return (
    <QuestionWrapper>
      <QuestionHeader>
        <TitleWrapper>
          <Title isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{question}</Title>
          <span style={{ color: `${isIframe && iframeTheme === IframeThemeType.light ? '#000046' : ''}` }}>{`${t(
            'quizPage.question_quiz',
            {
              ns: 'pages',
              activeQuestionIndex: activeQuestionIndex + 1,
              questionsLength: questions.length,
            },
          )}`}</span>

        </TitleWrapper>
        <ControlWrapper>
          <ControlButton
            isIframe={isIframe}
            iframeTheme={iframeTheme as IframeThemeType}
            onClick={() => {
              setActiveQuestionIndex(activeQuestionIndex - 1);
            }}
            disabled={!activeQuestionIndex}
          >
            <ArrowLeft isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} />
          </ControlButton>
          <ControlButton isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} onClick={onNextButtonHandler}>
            <ArrowRight isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} />
          </ControlButton>
        </ControlWrapper>
      </QuestionHeader>
      <QuestionBody>
        <AnswersWrapper>
          {answers.map((answer, index) => (
            <VisibleAnswer
              key={index}
              isIframe={isIframe}
              iframeTheme={iframeTheme as IframeThemeType}
              answerType={currentAnswer === answer ? (answer === correctAnswer ? 'correct' : 'incorrect') : undefined}
              onClick={() => handleAnswer(answer)}
              disabled={!!currentAnswer}
            >
              {answer}
            </VisibleAnswer>
          ))}
        </AnswersWrapper>
        {currentAnswer && correctAnswer !== currentAnswer && (
          <SeekToButton theme="white" onClick={() => goToTime(start as number)}>
            {t('question_watch_video')}
          </SeekToButton>
        )}
      </QuestionBody>
    </QuestionWrapper>
  );
};
