import styled, { css } from 'styled-components';

import { Button } from '@/components';
import { TitleH4, theme } from '@/styles';
import { IframeThemeType } from '@/types/iframeTypes';

export const QuizStyled = styled.div`
  position: relative;
  min-height: 345px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 30px;
  width: 100%;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const TitleWrapper = styled.div`
  width: 77%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 75%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const VisibleAnswer = styled.button<{ answerType?: 'correct' | 'incorrect'; iframeTheme: IframeThemeType, isIframe?: boolean }>`
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
  cursor: pointer;

  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: ${theme.colors.text.white_100};

  padding: 12px 30px;

  @media screen and (max-width: 480px) {
    padding: 6px 15px;
    font-size: 14px;
  }
  @keyframes correct-animation {
    0% {
      background-color: transparent;
      border-color: ${theme.colors.white.white_100};
    }
    100% {
      background-color: ${theme.colors.green.green_dark};
      border-color: ${theme.colors.green.green_light};
    }
  }

  @keyframes incorrect-animation {
    0% {
      background-color: transparent;
      border-color: ${theme.colors.white.white_100};
    }
    100% {
      border-color: ${theme.colors.red.red_light};
      background-color: ${theme.colors.red.red_light};
    }
  }

  ${({ answerType }) => {
    switch (answerType) {
      case 'correct':
        return css`
          border-color: ${theme.colors.green.green_light};
          background-color: ${theme.colors.green.green_dark};
          animation-name: correct-animation;
          /* &:active {
             
            animation-name: correct-animation;
            animation-duration: 0.2s;
            animation-iteration-count: 5;
            animation-timing-function: ease;
          } */
          &:active {
            animation-name: correct-animation;
            animation-duration: 0.2s;
            animation-iteration-count: 5;
            animation-timing-function: ease;
          }
        `;
      case 'incorrect':
        return css`
          border-color: ${theme.colors.red.red_light};
          background-color: ${theme.colors.red.red_light};
          /* :active {
            
            animation-name: incorrect-animation;
            animation-duration: 0.2s;
            animation-iteration-count: 5;
            animation-timing-function: ease;
          } */
          &:active {
            animation-name: incorrect-animation;
            animation-duration: 0.2s;
            animation-iteration-count: 5;
            animation-timing-function: ease;
          }
        `;
      default:
        return css`
          border-color: ${theme.colors.white.white_100};
          background-color: transparent;
        `;
    }
  }}
  &:hover {
    scale: 1.01;
    transition: scale 0.35s;
  }
  &:active {
    scale: 0.95;
    transition: 0.1s;
  }

  &:disabled {
    cursor: auto;

    :hover,
    :active {
      scale: 1;
    }
  }

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
      border-color: rgba(0, 0, 70, 0.4);
    `}
`;

export const Title = styled(TitleH4)<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  font-weight: 700;
  text-transform: uppercase;

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}

  @media screen and (max-width: 768px) {
    line-height: 22px;
    margin-bottom: 24px;
  }
`;
export const QuestionHeader = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;
export const QuestionBody = styled(QuestionHeader)``;

export const ControlWrapper = styled.div`
  display: flex;
  gap: 20px;
  max-height: 42px;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;
export const ControlButton = styled(Button)<{ iframeTheme: IframeThemeType, isIframe?: boolean }>`
  cursor: pointer;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(228, 228, 255);
  background: ${theme.colors.blue};
  padding: 13px 39px;

  &:hover {
    scale: 1.03;
    transition: scale 0.5s;
  }
  &:active {
    scale: 0.95;
    transition: 0.2s;
    border: 1px solid ${theme.colors.white.white_100};
    svg {
      & path {
        ${theme.colors.white.white_100};
      }
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
    background-color: #9197a3;

    :hover,
    :active {
      scale: 1;
    }
  }

  ${({ iframeTheme, isIframe }) =>
          isIframe && iframeTheme === IframeThemeType.light &&
    css`
      background-color: rgb(228, 228, 255);

      &:hover {
        scale: 1.03;
        transition: scale 0.5s;
      }

      &:active {
        scale: 0.95;
        transition: 0.2s;
        background-color: rgba(98, 84, 139, 0.6);

        svg {
          & path {
            ${theme.colors.white.white_100};
          }
        }
      }

      &:disabled {
        opacity: 0.5;
        cursor: auto;
        background-color: #9197a3;

        :hover,
        :active {
          scale: 1;
        }
      }
    `}
`;
export const SeekToButton = styled(Button)`
  padding: 8px 20px;
  height: 52px;
  margin-top: 0;
  margin-bottom: 30px;
  width: 75%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    padding: 8px 10px;
  }
`;
